import React, {useState, useEffect, useMemo, useRef} from 'react';
import { listQuestion } from '../../common/configData';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { chunkArray } from '../../common/function';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders,axiosWithHeadersNoTokenUploadFile} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum';
import { Calendar } from 'primereact/calendar';
import { Messages } from 'primereact/messages';
import moment from 'moment';
import { dataMenu } from '../../common/data';
// import PerfectScrollbar from 'react-perfect-scrollbar'

// import { FileUpload } from 'primereact/fileupload';

const _ = require("lodash");
const ModalAddImage = (props: any) =>{
    const {
        profile,
        showError,
        storeId,
        reloadPage
    } = props;

    const messages = useRef<any>(null)

    const [questions, setquestions] = useState<any>([]);
    const [listFile, setListFile] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);    
    const [dateSend, setDateSend] = useState<Date>();
    const [note, setNote] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);    

    
    useEffect(()=>{
        let questions: any = _.cloneDeep(listQuestion);
        questions = _.filter(questions, (item: any) => {
            return (
                item.questionId === 1 ||
                item.questionId === 2 ||
                item.questionId === 3 ||
                item.questionId === 4 ||
                item.questionId === 5 ||
                item.questionId === 16 ||
                item.questionId === 17
            )
        });

        questions.forEach(item =>{
            switch(item.questionType){
                case 1:
                    item.answer = "";
                    break;
                case 2:
                    item.answer = "";
                    break;
                case 3:
                    item.answer = "";
                    break;
                case 4:
                    item.answer = "";
                    break;
                case 5:
                    item.answer = "";
                    break;
                case 6:
                    item.answer = false;
                    break;
                case 7:
                    item.answer = "";
                    break;
                default:
                    break;
            }
        })
        setquestions(questions);
        // console.log(questions);
    }, [])

    const renderButtonAdd = () => {
        if(profile!==undefined && profile!==null && Object.keys(profile).length>0){
            if(profile.roleId ===1 || profile.roleId === 2){
                return (
                    <React.Fragment>
                        <Button text="Thêm hình ảnh" icon="plus" intent="none" onClick={(e: any)=> {
                            setShowModal(true)
                            setDateSend(new Date())
                        }}></Button>
                    </React.Fragment>
                )
            }
            return null;
        }
        return null;
    }

    const convertMultipleImageto64 = (file, questionId) => {
        // console.log(file)
        var f = new FormData() as any;
        let list: any = [...listFile];
        // console.log(isCustomerWed);
        file.map((image: any) => {
            f.append("files", image)
        });

        setLoading(true);

        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD_MULT}`, f)
            .then((response: any) => {
               // console.log(response)
                if (response !== undefined && response.data !== undefined && response.data.result !== undefined) {
                    const {result} = response.data;
                    result.map((item: any) => {
                        var obj = {
                            questionId: questionId,
                            answer: item.result,
                            infogroup: ""
                        }                    
                        list.push(obj); 
                    });
                    setListFile(list);                  
                }
            }).catch(err => console.log(err))
            .finally(()=>{
                setLoading(false);
            });
    }

    const onChangeFile = (event: any, questionId: number) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
            convertMultipleImageto64(fileList, questionId)         
        }
    }

    const renderListImageUpload = useMemo(() => {
        let html: any = [];
        chunkArray(listFile, 4).forEach((item, index) => {
            html.push(
                <div key={index} className="row" style={{ paddingTop: 10 }}>
                    {item.map((items, idx) =>
                        <div className="col-md-3 text-center" key={idx}>
                            <img src={items.answer} alt="Hình preview" style={{ width: "100%", height: 150, objectFit: "contain", border: "1px dashed #ccc" }} />
                        </div>
                    )}
                </div>
            )
        });
        return html;
    }, [listFile])

    const onValueChange = (e: any, index: number) =>{
        let listanswer: any = _.cloneDeep(questions);
        listanswer[index].answer  = e.target.value;
        setquestions(listanswer);
    }

    const closeModal = () =>{
        setListFile([]);
        setLoading(false);
        setNote("");
        setShowModal(false);
    }

    const footer = (
        <div>
            <Button text="THÊM MỚI" icon="plus" intent="primary" disabled={loading} onClick={(e: any)=>SaveData(e)}/>
            <Button text="QUAY VỀ" icon="cross" disabled={loading} onClick={closeModal}/>
        </div>
    );

    const renderBodyModal = () => {
        let html: any = [];
        if(questions.length > 0){
            questions.forEach((item: any, index: number) => {
                // console.log(item);
                switch(item.questionType){
                    case 1:
                        item.answer = "";
                        break;
                    case 2:
                        html.push(
                            <div className="row" style={{paddingTop: 10}} key={index}>
                                <div className="col-6 text-left align-self-center">
                                    <span>{item.questionName}</span>
                                </div>
                                <div className="col-6 text-left align-self-center">
                                    <input type="file"
                                        id={"import" + item.questionId}
                                        className="form-control" 
                                        multiple 
                                        style={{paddingTop: 5}}
                                        accept="image/*"
                                        onChange={(event: any) => onChangeFile(event, item.questionId)}
                                    />
                                </div>
                            </div>
                        )
                        break;
                    case 3:
                        item.answer = "";
                        break;
                    case 4:
                        html.push(
                            <div className="row" style={{paddingTop: 10}} key={index}>
                                <div className="col-6 text-left align-self-center">
                                    <span>{item.questionName}</span>
                                </div>
                                <div className="col-6 text-left align-self-center">
                                    <InputText 
                                        value={item.value} 
                                        placeholder="0" 
                                        style={{textAlign: "right"}}
                                        onChange={(e: any) => onValueChange(e, index)}
                                        keyfilter="int"
                                    />
                                </div>
                            </div>
                        )
                        break;
                    case 5:
                        item.answer = "";
                        break;
                    case 6:
                        item.answer = false;
                        break;
                    case 7:
                        item.answer = "";
                        break;
                    default:
                        break;
                }
            })
        }
        return html;
    }

    const validateForm = () =>{        
        // if(listFile === null || listFile.length === 0 )
        // {
        //     messages.current.show({ severity: 'error', summary: 'Lỗi !', detail: ' Vui lòng chọn hình !' });
        //     return false;
        // }
        if(dateSend === undefined || dateSend === null)
        {
            messages.current.show({ severity: 'error', summary: 'Lỗi !', detail: ' Vui lòng chọn thời gian !' });
            return false;
        }
        return true;
    }

    const SaveData = (e: any) =>{
        const check = validateForm();
        if(check){
            setLoading(true);
            let listData: any = _.cloneDeep(listFile);
            questions.forEach((item: any) => {
                if(item.questionId === 16 || item.questionId === 17){
                    listData.push(
                        {
                            questionId: item.questionId,
                            answer: item.answer,
                            infogroup: ""
                        }
                    )
                }                
            })

            // console.log({
            //     listData: listFile, 
            //     dateSend: moment(dateSend).format('YYYY-MM-DD HH:mm:ss.SSS'),
            //     storeId: storeId
            // });

            axiosWithHeaders('POST', `${api.DATA_INSERT_IMAGE}`, {
                listData: listData, 
                dateSend: moment(dateSend).format('YYYY-MM-DD HH:mm:ss.SSS'),
                storeId: storeId
            })
            .then((response: any) => {
                const {status, message } = response.data;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                      //  console.log(data[0].dataId)
                        reloadPage();
                        setListFile([]);
                        setLoading(false);
                        setNote("");
                        setShowModal(false);
                        break;
                    default:
                        showError('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                        break;
                }
            }).catch(err => console.log(err));
        }
    }

    return (
        <React.Fragment>
            {renderButtonAdd()}
            <Dialog header="THÊM HÌNH ẢNH" visible={showModal} style={{ width: '50%' }} onHide={() => setShowModal(false)} footer={footer}>
                <div className="row">
                    <div className="col-12">
                        <Messages ref={messages} style={{width: "100%"}} ></Messages>
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Thời gian gửi</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <Calendar 
                            // className="col-3" 
                            showTime={true} 
                            hourFormat="24" 
                            value={dateSend} 
                            dateFormat="dd/mm/yy" 
                            touchUI
                            onChange={(e: any) => setDateSend(e.value)}
                            style={{width: "100%"}}
                            >
                        </Calendar>
                    </div>
                </div>
                {renderBodyModal()}
                {(loading) && (
                    <div className="row" style={{paddingTop: 10}}>
                        <div className="col-12 text-center">
                            <ProgressSpinner />
                        </div>                    
                    </div>
                )}
                
                {renderListImageUpload}                
            </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    // console.log(state);
    return {
        profile:state.auth.profile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showError: (severity, summary, detail) => dispatch(actions.show_error(severity, summary, detail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddImage);