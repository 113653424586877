import * as types from '../contains/actionTypes';
import {
    CREATE_USER,
    UPDATE_USER,
    DETAIL_USER,
    DEL_USER
} from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';

import {
    axiosWithHeaders
} from '../contains/axiosWrapper';

import {
    show_error_init, 
    show_error
} from './error';

import {
    LoadDatatable
} from '../actions/index'

import {
    configTable,
    filterTable    
} from '../../common/configGrid/configUser'

export const user_init = () => {
    return {
        type: types.USER_INI
    };
};

export const user_success = (detail: any) => {
    return {
        type: types.USER_SUCCESS,
        detail
    }
}

export const user_loading = (loading: boolean) => {
    return {
        type: types.USER_LOADING,
        loading
    }
}

export const user_fail = (message) => {
    return {
        type: types.USER_FAIL,
        message
    }
}

export function GetDetailUser(userId: number) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(user_init())
        dispatch(user_loading(true));
        axiosWithHeaders('GET', `${DETAIL_USER}?userId=${userId}`, null)
            .then((data:any) => {
                // console.log(data)
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {status, result, message} = response;
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            // console.log(result)
                            dispatch(user_success(result));
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Không thể lấy thông tin user !"))
                }
                dispatch(user_loading(false));
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(user_loading(false));
            });
    }
}

export function CreateUser(obj: any, history: any) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(user_loading(true));        
        axiosWithHeaders('POST',CREATE_USER, obj)
        .then((data:any) => {
            console.log(data)
            if(data!==undefined && data!==null && Object.keys(data).length > 0){
                let response = data.data;
                let {status, message} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        // dispatch(user_success(null));
                        history.push(router.ListUser)
                        dispatch(show_error("success", "Thông báo", "Thêm user thành công !"))
                        break;
                    default:
                        dispatch(show_error("error", "Lỗi", message))
                        break;
                }
            }else{
                dispatch(show_error("error", "Lỗi", "Không thể tạo user !"))
            }
            dispatch(user_loading(false));
        }).catch(err => {
            console.log(err);
            dispatch(show_error("error", "Lỗi", err.toString()))
            dispatch(user_loading(false));
        }).finally(()=>{
            dispatch(show_error_init());
        })
    }
}

export function UpdateUser(obj: any, history: any) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(user_loading(true));
        axiosWithHeaders('POST',UPDATE_USER, obj)
        .then((data:any) => {
            console.log(data)
            if(data!==undefined && data!==null && Object.keys(data).length > 0){
                let response = data.data;
                let {status, message} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        dispatch(user_success(null));
                        dispatch(show_error("success", "Thông báo", "Cập nhật user thành công !"))
                        history.push(router.ListUser)
                        break;
                    default:
                        dispatch(show_error("error", "Lỗi", message))
                        break;
                }
            }else{
                dispatch(show_error("error", "Lỗi", "Không thể cập nhật user !"))
            }
            dispatch(user_loading(false));
        }).catch(err => {
            console.log(err);
            dispatch(show_error("error", "Lỗi", err.toString()))
            dispatch(user_loading(false));
        }).finally(()=>{
            dispatch(show_error_init());
        })
    }
}

export function BackButtonUser(history: any) {    
    return async dispatch => {
        dispatch(user_success(null));
        history.push(router.ListUser)
    }
}

export function DeleteUser(obj: any, pageNo: number, pageRows: number) {    
    return async dispatch => {
        // datadefault_ini()
        dispatch(show_error_init());
        dispatch(user_loading(true));
        axiosWithHeaders('POST', DEL_USER, obj)
        .then((data:any) => {
            console.log(data)
            if(data!==undefined && data!==null && Object.keys(data).length > 0){
                let response = data.data;
                let {status, message} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        dispatch(show_error("success", "Thông báo", "Xóa user thành công !"))
                        dispatch(LoadDatatable(configTable, filterTable, pageNo, pageRows, false))
                        break;
                    default:
                        dispatch(show_error("error", "Lỗi", message))
                        break;
                }
            }else{
                dispatch(show_error("error", "Lỗi", "Không thể xóa tin user !"))
            }
            dispatch(user_loading(false));
        }).catch(err => {
            console.log(err);
            dispatch(show_error("error", "Lỗi", err.toString()))
            dispatch(user_loading(false));
        }).finally(()=>{
            dispatch(show_error_init());
        })
    }
}