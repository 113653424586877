import React, {useState, useEffect, useMemo, useRef} from 'react';
import { listQuestion } from '../../common/configData';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { dataStatus } from './../../common/configData'
import { Dropdown } from 'primereact/dropdown';
import { chunkArray } from '../../common/function';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders,axiosWithHeadersNoTokenUploadFile} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum';
import { Calendar } from 'primereact/calendar';
import { Messages } from 'primereact/messages';
import moment from 'moment';
// import PerfectScrollbar from 'react-perfect-scrollbar'

// import { FileUpload } from 'primereact/fileupload';

const _ = require("lodash");
const ModalCustomerInfo = (props: any) =>{
    const {
        profile,
        showError,
        storeId,
        reloadPage,
        //Xử lý update
        customerStatus,
        isShow,
        onChangeShow,
        data,
        listSuppg
    } = props;

    const messages = useRef<any>(null)

    const [questions, setquestions] = useState<any>([]);
    const [listFile, setListFile] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);    
    const [dateSend, setDateSend] = useState<Date>();
    const [dataId, setdataId] = useState<number>(0);
    const [customerId, setCustomerId] = useState<number>(0);
    const [nameCustomer, setnameCustomer] = useState<string>("");
    const [phoneCustomer, setphoneCustomer] = useState<string>("");
    const [locBooth, setlocBooth] = useState<number>(0);
    const [thungBooth, setthungBooth] = useState<number>(0);
    const [loc, setloc] = useState<number>(0);
    const [thung, setthung] = useState<number>(0);
    const [bigGift, setbigGift] = useState<number>(0);
    const [smallGift, setsmallGift] = useState<number>(0);
    const [infogroup, setinfogroup] = useState<string>("");
    const [status, setstatus] = useState<number>(0);
    const [disableStatus, setdisableStatus] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);    

    const [userId, setuserId] = useState<number>(0);
    const [userAccept, setuserAccept] = useState<number>(0);
    
    useEffect(()=>{
        let questions: any = _.cloneDeep(listQuestion);
        questions = _.filter(questions, (item: any) => {
            return (
                item.questionId === 14 ||
                item.questionId === 15
            )
        });

        questions.forEach(item =>{
            switch(item.questionType){
                case 1:
                    item.answer = "";
                    break;
                case 2:
                    item.answer = "";
                    break;
                case 3:
                    item.answer = "";
                    break;
                case 4:
                    item.answer = "";
                    break;
                case 5:
                    item.answer = "";
                    break;
                case 6:
                    item.answer = false;
                    break;
                case 7:
                    item.answer = "";
                    break;
                default:
                    break;
            }
        })
        setquestions(questions);
    }, []);

    useEffect(()=>{
        setShowModal(isShow);
    }, [isShow]);

    useEffect(()=>{
        if(data!==undefined && data!==null && Object.keys(data).length > 0){
            const {
                dataId,
                customerId,
                dateCreate,
                locBooth,
                thungBooth,
                loc,
                thung,
                smallGift,
                bigGift,
                nameCustomer,
                phoneCustomer,
                userCreate,
                userAccept,
            } = data;
            setstatus(customerStatus);
            setdataId(dataId);
            setCustomerId(customerId);
            setlocBooth(locBooth);
            setthungBooth(thungBooth);
            setloc(loc);
            setthung(thung);
            setbigGift(bigGift);
            setsmallGift(smallGift);
            setnameCustomer(nameCustomer);
            setphoneCustomer(phoneCustomer);
            setdisableStatus(true);
            setDateSend(new Date(dateCreate.toString().replace("T", " ")));
            setuserId(userCreate);
            setuserAccept(userAccept);
        }
        else{
            setstatus(0);
            setdataId(0);
            setCustomerId(0);
            setlocBooth(0);
            setthungBooth(0);
            setloc(0);
            setthung(0);
            setbigGift(0);
            setsmallGift(0);
            setnameCustomer("");
            setphoneCustomer("");
            setdisableStatus(false);
            setDateSend(new Date());
            setuserId(0);
            setuserAccept(0);
        }
    }, [data])

    const renderButtonAdd = () => {
        if(profile!==undefined && profile!==null && Object.keys(profile).length>0){
            if((profile.roleId ===1 || profile.roleId === 2)&&(customerStatus===0)){
                return (
                    <React.Fragment>
                        <Button text="Thêm thông tin khách hàng" icon="plus" intent="none" onClick={(e: any)=> {
                            setShowModal(true);                            
                            setDateSend(new Date());
                        }}
                        style={{marginRight: 10}}
                        ></Button>
                    </React.Fragment>
                )
            }
            return null;
        }
        return null;
    }

    const convertMultipleImageto64 = (file, questionId) => {
        var f = new FormData() as any;
        let list: any = [...listFile];
        file.map((image: any) => {
            f.append("files", image)
        });

        setLoading(true);

        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD_MULT}`, f)
            .then((response: any) => {
                if (response !== undefined && response.data !== undefined && response.data.result !== undefined) {
                    const {result} = response.data;
                    result.map((item: any) => {
                        var obj = {
                            questionId: questionId,
                            answer: item.result,
                            // infogroup: ""
                        }                    
                        list.push(obj); 
                    });
                    setListFile(list);                  
                }
            }).catch(err => console.log(err))
            .finally(()=>{
                setLoading(false);
            });
    }

    const onChangeFile = (event: any, questionId: number) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
            convertMultipleImageto64(fileList, questionId)         
        }
    }

    const resetData = () =>{
        setnameCustomer("");
        setphoneCustomer("");
        setlocBooth(0);
        setthungBooth(0);
        setloc(0);
        setthung(0);
        setbigGift(0);
        setsmallGift(0);
        setinfogroup("");
        setstatus(0);
        setdisableStatus(false);
        setListFile([]);
        setLoading(false);
        setuserId(0);
        setuserAccept(0);
    }

    const renderListImageUpload = useMemo(() => {
        let html: any = [];
        chunkArray(listFile, 4).forEach((item, index) => {
            html.push(
                <div key={index} className="row" style={{ paddingTop: 10 }}>
                    {item.map((items, idx) =>
                        <div className="col-md-3 text-center" key={idx}>
                            <img src={items.answer} alt="Hình preview" style={{ width: "100%", height: 150, objectFit: "contain", border: "1px dashed #ccc" }} />
                        </div>
                    )}
                </div>
            )
        });
        return html;
    }, [listFile])

    const onValueChange = (e: any, index: number) =>{
        let listanswer: any = _.cloneDeep(questions);
        listanswer[index].answer  = e.target.value;
        setquestions(listanswer);
    }

    const closeModal = () =>{
        // setNote("");
        setShowModal(false);
        resetData();
        if(customerStatus!==0){
            onChangeShow();
        }
    }

    const footer = (
        <div>
            {(customerStatus === 0) && (
                <Button text="THÊM MỚI" icon="plus" intent="primary" disabled={(loading===true || status ===0) ? true: false} onClick={(e: any)=>SaveData(e)}/>
            )}
            {(customerStatus !== 0) && (
                <Button text="CẬP NHẬT" icon="plus" intent="success" disabled={loading} onClick={(e: any)=> SaveUpdateData(e)}/>
            )}
            <Button text="QUAY VỀ" icon="cross" disabled={loading} onClick={closeModal}/>
        </div>
    );

    const renderBodyModal = () => {
        let html: any = [];
        if(questions.length > 0){
            questions.forEach((item: any, index: number) => {
                switch(item.questionType){
                    case 1:
                        item.answer = "";
                        break;
                    case 2:
                        html.push(
                            <div className="row" style={{paddingTop: 10}} key={index}>
                                <div className="col-6 text-left align-self-center">
                                    <span>{item.questionName}</span>
                                </div>
                                <div className="col-6 text-left align-self-center">
                                    <input type="file"
                                        id={"import" + item.questionId}
                                        className="form-control" 
                                        multiple 
                                        style={{paddingTop: 5}}
                                        accept="image/*"
                                        onChange={(event: any) => onChangeFile(event, item.questionId)}
                                        disabled={(status===0)?true: false}
                                    />
                                </div>
                            </div>
                        )
                        break;
                    case 3:
                        item.answer = "";
                        break;
                    case 4:
                        html.push(
                            <div className="row" style={{paddingTop: 10}} key={index}>
                                <div className="col-6 text-left align-self-center">
                                    <span>{item.questionName}</span>
                                </div>
                                <div className="col-6 text-left align-self-center">
                                    <InputText 
                                        value={item.value} 
                                        placeholder="0" 
                                        style={{textAlign: "right"}}
                                        onChange={(e: any) => onValueChange(e, index)}
                                        keyfilter="int"
                                        disabled={(status===0)?true: false}
                                    />
                                </div>
                            </div>
                        )
                        break;
                    case 5:
                        item.answer = "";
                        break;
                    case 6:
                        item.answer = false;
                        break;
                    case 7:
                        item.answer = "";
                        break;
                    default:
                        break;
                }
            })
        }
        return html;
    }

    const validateForm = () =>{        
        if(nameCustomer===undefined || nameCustomer === null || nameCustomer.trim() === ""){
            messages.current.show({ severity: 'error', summary: 'Lỗi !', detail: ' Vui lòng nhập tên khách hàng !' });
            return false;
        }
        if((listFile === null || listFile.length === 0) && (status === 2) && (dataId === 0))
        {
            messages.current.show({ severity: 'error', summary: 'Lỗi !', detail: ' Vui lòng chọn hình !' });
            return false;
        }
        if(dateSend === undefined || dateSend === null)
        {
            messages.current.show({ severity: 'error', summary: 'Lỗi !', detail: ' Vui lòng chọn thời gian !' });
            return false;
        }
        return true;
    }

    const SaveData = (e: any) =>{
        const check = validateForm();
        if(check){
            setLoading(true);
            let listData: any = _.cloneDeep(listFile);

            const objUpdate: any = {
                storeId: storeId,
                dateSend: moment(dateSend).format('YYYY-MM-DD HH:mm:ss.SSS'),
                customerId: 0,
                nameCustomer: nameCustomer,
                phoneCustomer: phoneCustomer,
                locBooth: locBooth,
                thungBooth: thungBooth,
                loc: loc,
                thung: thung,
                bigGift: bigGift,
                smallGift: smallGift,
                infogroup: moment(dateSend).format('YYYY-MM-DD HH:mm:ss.SSS'),
                status: status,
                images: listData,
                userId: userId,
                userAccept: userAccept
            }

            axiosWithHeaders('POST', `${api.CUSTOMER_INSERT_WEB}`, objUpdate)
            .then((response: any) => {
                const {status, message } = response.data;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        showError('success', 'Thông báo', "Thêm mới khách hàng thành công !");
                        reloadPage();
                        setListFile([]);
                        setLoading(false);
                        // setNote("");
                        setShowModal(false);
                        resetData();
                        break;
                    default:
                        showError('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                        break;
                }
            }).catch(err => console.log(err));
        }
    }

    const SaveUpdateData = (e: any) =>{
        const check = validateForm();
        if(check){
            setLoading(true);
            let listData: any = _.cloneDeep(listFile);
            const objUpdate: any = {
                customerId: customerId,
                dataId: dataId,                
                nameCustomer: nameCustomer,
                phoneCustomer: phoneCustomer,
                locBooth: locBooth,
                thungBooth: thungBooth,
                loc: loc,
                thung: thung,
                bigGift: bigGift,
                smallGift: smallGift,
                status: status,
                images: listData,
                userId: userId,
                userAccept: userAccept,
                dateSend: moment(dateSend).format('YYYY-MM-DD HH:mm:ss.SSS'),
            }

            axiosWithHeaders('POST', `${api.CUSTOMER_UPDATE_WEB}`, objUpdate)
            .then((response: any) => {
                const {status, message } = response.data;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        showError('success', 'Thông báo', "Cập nhật khách hàng thành công !");
                        reloadPage();
                        setListFile([]);
                        setLoading(false);
                        // setNote("");
                        setShowModal(false);
                        resetData();
                        if(customerStatus!==0){
                            onChangeShow();
                        }
                        break;
                    default:
                        showError('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                        break;
                }
            }).catch(err => console.log(err));
        }
    }

    return (
        <React.Fragment>
            {renderButtonAdd()}
            <Dialog header={"THÔNG TIN KHÁCH HÀNG"} visible={showModal} style={{ width: '50%' }} onHide={() => closeModal()} footer={footer}>
                <div className="row">
                    <div className="col-12">
                        <Messages ref={messages} style={{width: "100%"}} ></Messages>
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Thời gian gửi</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <Calendar 
                            // className="col-3" 
                            showTime={true} 
                            hourFormat="24" 
                            value={dateSend} 
                            dateFormat="dd/mm/yy" 
                            touchUI
                            onChange={(e: any) => setDateSend(e.value)}
                            style={{width: "100%"}}
                            >
                        </Calendar>
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Tài khoản tại booth</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <Dropdown 
                            value={userId} 
                            options={listSuppg} 
                            optionLabel="name" 
                            optionValue="userId"
                            filter
                            filterBy="name"
                            onChange={(e) => {setuserId(e.value)}} 
                            style={{width: "100%"}}
                            placeholder="Chọn tài khoản tại booth"                            
                        />
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Tài khoản khảo sát</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <Dropdown 
                            value={userAccept} 
                            options={listSuppg} 
                            optionLabel="name" 
                            optionValue="userId"
                            filter
                            filterBy="name"
                            onChange={(e) => {setuserAccept(e.value)}} 
                            style={{width: "100%"}}
                            placeholder="Chọn tài khoản khảo sát"                            
                        />
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Loại khách hàng</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <Dropdown 
                            value={status} 
                            options={dataStatus} 
                            onChange={(e) => {setstatus(e.value)}} 
                            style={{width: "100%"}}
                            placeholder="Chọn loại khách hàng"
                            disabled={disableStatus}
                        />
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Tên khách hàng</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <InputText 
                            value={nameCustomer} 
                            placeholder="Nhập tên khách hàng"                             
                            onChange={(e: any) => setnameCustomer(e.target.value)}
                            disabled={(status===0)?true: false}
                        />
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Số điện thoại</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <InputText 
                            value={phoneCustomer} 
                            placeholder="Nhập số điện thoại"                             
                            onChange={(e: any) => setphoneCustomer(e.target.value)}
                            disabled={(status===0)?true: false}
                        />
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Số lốc đăng ký</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <InputNumber 
                            value={locBooth} 
                            placeholder="0"                             
                            onValueChange={(e: any) => setlocBooth(e.target.value)}
                            disabled={(status===0)?true: false}
                        />
                    </div>
                </div>
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-6 text-left align-self-center">
                        <span>Số thùng đăng ký</span>
                    </div>
                    <div className="col-6 text-left align-self-center">
                        <InputNumber 
                            value={thungBooth} 
                            placeholder="0"                             
                            onValueChange={(e: any) => setthungBooth(e.target.value)}
                            disabled={(status===0)?true: false}
                        />
                    </div>
                </div>
                {(status===2)&&(
                    <React.Fragment>
                        <div className="row" style={{paddingTop: 10}}>
                            <div className="col-6 text-left align-self-center">
                                <span>Số lốc</span>
                            </div>
                            <div className="col-6 text-left align-self-center">
                                <InputNumber 
                                    value={loc} 
                                    placeholder="0"                             
                                    onValueChange={(e: any) => setloc(e.target.value)}                                    
                                />
                            </div>
                        </div>
                        <div className="row" style={{paddingTop: 10}}>
                            <div className="col-6 text-left align-self-center">
                                <span>Số thùng</span>
                            </div>
                            <div className="col-6 text-left align-self-center">
                                <InputNumber 
                                    value={thung} 
                                    placeholder="0"                             
                                    onValueChange={(e: any) => setthung(e.target.value)}                                    
                                />
                            </div>
                        </div>
                        <div className="row" style={{paddingTop: 10}}>
                            <div className="col-6 text-left align-self-center">
                                <span>Quà lớn</span>
                            </div>
                            <div className="col-6 text-left align-self-center">
                                <InputNumber 
                                    value={bigGift} 
                                    placeholder="0"                             
                                    onValueChange={(e: any) => setbigGift(e.target.value)}                                    
                                />
                            </div>
                        </div>
                        <div className="row" style={{paddingTop: 10}}>
                            <div className="col-6 text-left align-self-center">
                                <span>Quà nhỏ</span>
                            </div>
                            <div className="col-6 text-left align-self-center">
                                <InputNumber 
                                    value={smallGift} 
                                    placeholder="0"                             
                                    onValueChange={(e: any) => setsmallGift(e.target.value)}                                    
                                />
                            </div>
                        </div>                        
                        {renderBodyModal()}
                    </React.Fragment>
                )}
                {(loading) && (
                    <div className="row" style={{paddingTop: 10}}>
                        <div className="col-12 text-center">
                            <ProgressSpinner />
                        </div>                    
                    </div>
                )}
                
                {renderListImageUpload}                
            </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profile:state.auth.profile,
        listSuppg: state.listusersuppg.result,
        data: state.customerdetail.result
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showError: (severity, summary, detail) => dispatch(actions.show_error(severity, summary, detail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCustomerInfo);