import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import FormLayout from '../../components/form/index';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Dropdown} from 'primereact/dropdown';
import { Button } from "@blueprintjs/core";
import { listRole} from '../../common/data';
import {
    withRouter
} from "react-router-dom";

const _ = require("lodash");

const User = (props: any) =>{
    const {
        profile,
        user,
        loading,
        history,
        GetDetailUser,
        UpdateUser,
        CreateUser,
        BackUser
    } = props;

    const [username, setUsername] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [roleId, setRoleId] = useState<number>(0);
    const [password, setPassword] = useState<string>("");
    const [userId, setUserId] = useState<number>(0);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    
    useEffect(()=>{
        let userId = (props.match.params.id);
        if(userId!==undefined && userId!== null && isNaN(userId) !==true ){
            setIsUpdate(true);
            GetDetailUser(userId);
        }
    }, [])

    useEffect(()=>{
        if(user!==undefined && user!==null && Object.keys(user).length > 0){            
            let {
                userId,
                name,
                roleId,
                username
            } = user;
    
            setUserId(userId);                        
            setName(name);
            setRoleId(roleId);
            setUsername(username);            
        }
    }, [user]);

    const updateData  = (e: any) =>{
        e.preventDefault();
        let objUpdate: any = {         
            username: username,          
            name: name,         
            roleId: roleId, 
            password: password,
        }

        if(isUpdate){
            objUpdate.userId = userId;  
            UpdateUser(objUpdate, history) 
            return;       
        }
        CreateUser(objUpdate, history);       
        return;
    }

    const renderListRole = () =>{
        return (
            <div className="p-grid">
                <div className="p-col-4 text-right">
                    <label>Quyền truy cập</label>
                </div>
                <div className="p-col-6">
                    <Dropdown 
                        value={roleId} 
                        options={listRole(profile.roleId)} 
                        onChange={(e: any)=> setRoleId(e.target.value)}
                        filter={true} 
                        filterPlaceholder="Tìm..." 
                        filterBy="label,value"
                        placeholder="Chọn quyền truy cập..."
                        filterMatchMode="contains"
                        style={{width: "100%"}}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="booking-layout add-user">
            <FormLayout formTitle="NHÂN VIÊN">               
                <div className="p-grid">
                    <div className="p-col-4">
                    </div>
                    <div className="p-col-4">
                        {
                            renderListRole()
                        }
                          <div className="p-grid">
                            <div className="p-col-4 text-right">
                                <label>Tên đăng nhập</label>
                            </div>
                            <div className="p-col-6">
                                <InputText 
                                    value={username} 
                                    placeholder="Nhập tài khoản...." 
                                    onChange={(e: any)=> setUsername(e.target.value)} 
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-4 text-right">
                                <label>Họ và tên</label>
                            </div>
                            <div className="p-col-6">
                                <InputText 
                                    value={name} 
                                    placeholder="Nhập họ tên...." 
                                    onChange={(e: any)=> setName(e.target.value)} 
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>
                        {
                            !isUpdate && (
                                <div className="p-grid">
                                    <div className="p-col-4 text-right">
                                        <label>Mật khẩu</label>
                                    </div>
                                    <div className="p-col-6">
                                        <Password 
                                            value={password} 
                                            placeholder="Nhập mật khẩu...." 
                                            onChange={(e: any)=> setPassword(e.target.value)} 
                                            style={{width: "100%"}}
                                        />
                                    </div>
                                </div>
                            )
                        }
                     
                      
                         <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Button
                                    intent="none"
                                    className="btn-block"
                                    large
                                    onClick={(e)=> BackUser(history)}
                                    disabled={loading}
                                >
                                    QUAY LẠI
                                </Button>
                            </div>
                            <div className="p-col-6">
                                {!isUpdate ? <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={(e)=> updateData(e)}
                                    disabled={loading}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    onClick={(e)=> updateData(e)}
                                    disabled={loading}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="p-col-4"></div>
                </div>
            </FormLayout>
        </div>
    );
}
const mapStateToProps = state => {
    // console.log(state);
    return {
        profile: state.auth.profile,
        user: state.user.detail,
        loading: state.user.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        GetDetailUser: (userId: number) => dispatch(actions.GetDetailUser(userId)),
        CreateUser: (objUser: any, history: any) => dispatch(actions.CreateUser(objUser, history)),
        UpdateUser: (objUser: any, history: any) => dispatch(actions.UpdateUser(objUser, history)),
        BackUser: (history: any) => dispatch(actions.BackButtonUser(history)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));