import React, {useState, useEffect} from 'react';
import {filterTable, columnTable,configTable} from '../../common/configGrid/StoreUserTable';
import { Button } from "@blueprintjs/core";
import {Fieldset} from 'primereact/fieldset';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
} from "react-router-dom";
import * as router from '../../common/router';
import RenderFilter from '../../components/filter/index';
import FormLayout from '../../components/form/index';
import DataTables from '../../components/datatable/index';
import * as Status from '../../common/enum'
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as config from '../../common/config';
import * as actions from '../../reducer/actions/index';
import Swal from 'sweetalert2';
const _ = require("lodash");
const ListStoreUser = (props: any) =>{
    const {
        profile,
        data,
        first,
        loading,
        pageNo,
        pageRows,
        totalRecords,
        LoadDataTable,
        DeleteUser
    } = props;
    const [listFilter, setListFilter] = useState<any>(filterTable);
    const [isNewFilter, setIsNewFilter] = useState<boolean>(false);
    const [filter, setFilter] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const onChangeFilter = (value, index) =>{
        let filters = _.cloneDeep(listFilter);
        filters[index].value = value;
        setListFilter(filters);
        setIsNewFilter(true);
    }

    const renderFilter = () => {
        // console.log(filter);
        return(
            <Fieldset legend="TÌM KIẾM NÂNG CAO" className="booking-custom" style={{position: "relative"}}>
                <RenderFilter 
                    listFilter={listFilter} 
                    isNewFilter={isNewFilter}
                    onClickFilter={(e: any) => LoadDataTable(configTable, listFilter, config.DEFAULT_PAGE_NO, config.DEFAULT_PAGE_SIZE, true)}
                    onChangeFilter={onChangeFilter}
                />
            </Fieldset>
        )
    }

    useEffect(() => {
        LoadDataTable(configTable, listFilter, config.DEFAULT_PAGE_NO, config.DEFAULT_PAGE_SIZE, true);
    }, []);

    const delCustomer = (e: any, storeId: number, userId: number) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa  khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_STORE_USER, {storeId: storeId,userId:userId})
                .then((data: any) => {
                    if(data!==undefined && data!==null && Object.keys(data).length > 0){
                        let response = data;
                        let {status} = response;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                
                                break;
                            case Status.STATUS_RESPONSE_FORBIDDEN:
                                props.logOut();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi ", "error");
                });
            }
        })
    }

    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        // console.log(field);
        switch(field){
            case 'action':
                return (
                    <>
                       
                        <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={(e)=> delCustomer(e, row["storeId"],row["userId"])}/>
                    </>
                )
            default:
                return null;
        }
    }

    const renderDataTable = () => {
      //  console.log(data);
        if(columnTable!==undefined && columnTable!== null && Array.from(columnTable).length > 0){
            return (
                <DataTables 
                    columns={columnTable}
                    loading={loading}
                    data={data}
                    rows={pageRows}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={onPage}
                    cellTemplate={cellTemplate}
                />
            )
        }
        return null
    }

    

    const onClickAdd = (e: any) => {
        e.preventDefault();
        props.history.push(router.StoreUser);
    }

    const onPage = (e: any) => {
        let {page, rows} = e;
        LoadDataTable(configTable, listFilter, page, rows, false)
    }

    return (
        <>
            <FormLayout 
                formTitle="Nhân viên - cửa hàng" 
                showAdd={true} 
                onClickAdd={(e: any)=> onClickAdd(e)}
                formName="user"
            >
                {
                    renderFilter()
                }
                {
                    renderDataTable()
                }
            </FormLayout>
        </>
    )
}

const mapStateToProps = state => {
    return {
        profile: state.auth.profile,
        data: state.datatable.data,
        pageRows: state.datatable.pageRows,
        pageNo: state.datatable.pageNo,
        loading: state.datatable.loading,
        totalRecords: state.datatable.totalRecords,
        first: state.datatable.first,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(actions.logOut()),
        show_error: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail)),
        LoadDataTable: (settingTable: any, filterTable: any, pageNo: number, pageSize: number, isNewFilter: boolean) => dispatch(actions.LoadDatatable(settingTable, filterTable, pageNo, pageSize, isNewFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListStoreUser)) as any;
