import React, {useState, useEffect} from 'react';
import {filterTable, columnTable, configTable} from '../../common/configGrid/configUser';
import { Button } from "@blueprintjs/core";
import {Fieldset} from 'primereact/fieldset';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
} from "react-router-dom";
import * as router from '../../common/router';
import RenderFilter from '../../components/filter/update';
import FormLayout from '../../components/form/index';
import DataTables from '../../components/datatable/index';
import * as config from '../../common/config';
import * as actions from '../../reducer/actions/index';
import Swal from 'sweetalert2';

const _ = require("lodash");


const ListCustomer = (props: any) =>{
    // const {profile} = props;
    const {
        profile,
        data,
        first,
        loading,
        pageNo,
        pageRows,
        totalRecords,
        LoadDataTable,
        DeleteUser
    } = props;
    const [listFilter, setListFilter] = useState<any>(filterTable);
    const [isNewFilter, setIsNewFilter] = useState<boolean>(false);

    const onChangeFilter = (value, index) =>{
        let filters = _.cloneDeep(listFilter);
        filters[index].value = value;
        setListFilter(filters);
        setIsNewFilter(true);
    }

    const renderFilter = () => {
        // console.log(filter);
        return(
            <Fieldset legend="TÌM KIẾM NÂNG CAO" className="booking-custom" style={{position: "relative"}}>
                <RenderFilter 
                    listFilter={listFilter} 
                    isNewFilter={isNewFilter}
                    onClickFilter={(e: any) => LoadDataTable(configTable, listFilter, config.DEFAULT_PAGE_NO, config.DEFAULT_PAGE_SIZE, true)}
                    onChangeFilter={onChangeFilter}
                />
            </Fieldset>
        )
    }

    useEffect(() => {
        LoadDataTable(configTable, listFilter, config.DEFAULT_PAGE_NO, config.DEFAULT_PAGE_SIZE, true);
    }, []);

    const delUser = (e: any, userId: number) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa nhân viên này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                const objDel = {
                    userId: userId
                }
                DeleteUser(objDel, pageNo, pageRows);
            }
        })
    }

    // const resetPassword = (e: any, userId: number) => {
    //     e.preventDefault();
    //     Swal.fire({
    //         title: 'Thông báo',
    //         text: "Bạn có muốn đặt lại mật khẩu cho nhân viên này không ?",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Đồng ý',
    //         cancelButtonText: "Hủy"
    //     }).then((result) => {
    //         if (result.value) {

    //             const data = enCryptData({userId: userId});
    //             // console.log(data);

    //             axiosWithHeaders('POST', RESET_PASSWORD, {data: data})
    //             .then((data: any) => {
    //                 if(data!==undefined && data!==null && Object.keys(data).length > 0){
    //                     let response = data;
    //                     let {status} = response;
    //                     switch(status){
    //                         case Status.STATUS_RESPONSE_OK:
    //                             // Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
    //                             showError("success", "Thông báo", "Đặt lại mật khẩu thành công");
    //                             getDataTable(filter, pageNo, pageRows);
    //                             break;
    //                         case Status.STATUS_RESPONSE_FORBIDDEN:
    //                             props.logOut();
    //                             break;
    //                         default:
    //                             // Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
    //                             showError("error", "Thông báo", "Hệ thống đang xảy ra lỗi " + status);
    //                             break;
    //                     }
    //                 }else{
    //                     showError("error", "Thông báo", "Hệ thống đang xảy ra lỗi !");
    //                 }
    //             }).catch(err => {
    //                 console.log(err);
    //                 showError("error", "Thông báo", "Hệ thống đang xảy ra lỗi !");
    //             });
    //         }
    //     })
    //     // console.log(deCryptData("U2FsdGVkX19pSdhbymuEhe5uo8NDAoGfX4j/CzZjAgQjx7l06CTNvoVIlvEMqqE3apVr9Ex+6s2Farbj5BYDDA=="))
    // }

    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        // console.log(field);
        switch(field){
            case 'action':
                return (
                    <>
                        <Link className="bp3-button bp3-small bp3-intent-primary add-booking" title="Cập nhật nhân viên" to={router.User+"/"+ row["userId"]}>
                            <i className="fa fa-edit"></i>
                        </Link>
                        {(profile!==undefined && profile!==null && profile.roleId === 1)&&(
                            <>
                                <Button icon="trash" intent="danger" title="Xóa nhân viên" small style={{marginLeft: 5}} onClick={(e)=> delUser(e, row["userId"])}/>
                                {/* <Button icon="reset" intent="warning" title="Reset mật khẩu" small style={{marginLeft: 5}} onClick={(e)=> resetPassword(e, row["userId"])}/> */}
                            </>
                        )}
                    </>
                )
            default:
                return null;
        }
    }

    const renderDataTable = () => {
        // console.log(data);
        if(columnTable!==undefined && columnTable!== null && Array.from(columnTable).length > 0){
            return (
                <DataTables 
                    columns={columnTable}
                    loading={loading}
                    data={data}
                    rows={pageRows}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={onPage}
                    cellTemplate={cellTemplate}
                />
            )
        }
        return null
    }

    

    const onClickAdd = (e: any) => {
        e.preventDefault();
        props.history.push(router.User);
    }

    const onPage = (e: any) => {
        let {page, rows} = e;
        LoadDataTable(configTable, listFilter, page, rows, false)
    }

    return (
        <>
            <FormLayout 
                formTitle="Nhân viên" 
                showAdd={true} 
                onClickAdd={(e: any)=> onClickAdd(e)}
                formName="user"
            >
                {
                    renderFilter()
                }
                {
                    renderDataTable()
                }
            </FormLayout>
        </>
    )
}

const mapStateToProps = state => {
    return {
        profile: state.auth.profile,
        data: state.datatable.data,
        pageRows: state.datatable.pageRows,
        pageNo: state.datatable.pageNo,
        loading: state.datatable.loading,
        totalRecords: state.datatable.totalRecords,
        first: state.datatable.first,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        showError: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail)),
        LoadDataTable: (settingTable: any, filterTable: any, pageNo: number, pageSize: number, isNewFilter: boolean) => dispatch(actions.LoadDatatable(settingTable, filterTable, pageNo, pageSize, isNewFilter)),
        DeleteUser: (objDel: any, pageNo: number, pageRows: number) =>dispatch(actions.DeleteUser(objDel, pageNo, pageRows)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListCustomer)) as any;
