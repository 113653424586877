import * as types from '../contains/actionTypes';
import {
    DATA_LIST_BY_STORE,
} from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';

import {
    axiosWithHeaders
} from '../contains/axiosWrapper';

import {
    show_error_init, 
    show_error
} from './error';

export const data_list_by_store_init = () => {
    return {
        type: types.DATA_LIST_BY_STORE_INI
    };
};

export const data_list_by_store_success = (result: any, detail: any) => {
    return {
        type: types.DATA_LIST_BY_STORE_SUCCESS,
        result,
        detail
    }
}

export const data_list_by_store_loading = (loading: boolean) => {
    return {
        type: types.DATA_LIST_BY_STORE_LOADING,
        loading
    }
}

export const data_list_by_store_fail = (message) => {
    return {
        type: types.DATA_LIST_BY_STORE_FAIL,
        message
    }
}

export function GetDataListByStore(storeId: number) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(data_list_by_store_init())
        dispatch(data_list_by_store_loading(true));
        axiosWithHeaders('GET', `${DATA_LIST_BY_STORE}?storeId=${storeId}`, null)
            .then((data:any) => {
                // console.log(data)
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {
                        status, 
                        result,                        
                        detail,
                        message
                    } = response;
                    // console.log(response);
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            dispatch(data_list_by_store_success(result, detail));
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Không thể lấy thông tin chi tiết !"))
                }
                dispatch(data_list_by_store_loading(false));
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(data_list_by_store_loading(false));
            });
    }
}

// export function BackButtonData(history: any) {    
//     return async dispatch => {
//         dispatch(data_list_by_store_init());
//         history.push(router.ListData)
//     }
// }
