// LOADING
export const LOADING = 'LOADING';

export const AUTH_INI = 'AUTH_INI';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_RELOAD_TOKEN = 'AUTH_RELOAD_TOKEN';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';


//ERROR
export const ERROR_INI = 'ERROR_INI';
export const show_error = 'show_error';


//WAREHOUSE
export const WAREHOUSE_INI = 'WAREHOUSE_INI';
export const WAREHOUSE_SUCCESS = 'WAREHOUSE_SUCCESS';
export const WAREHOUSE_FAIL = 'WAREHOUSE_FAIL';

//ROUTER
export const ROUTER_INI = 'ROUTER_INI';
export const ROUTER_DATA_SUCCESS = 'ROUTER_DATA_SUCCESS';
export const ROUTER_DROPDOWN_SUCCESS = 'ROUTER_DROPDOWN_SUCCESS';
export const ROUTER_FAIL = 'ROUTER_FAIL';

//DATA DEFAULT
export const DATA_DEFAULT_INI = 'DATA_DEFAULT_INI';
export const DATA_DEFAULT_SUCCESS = 'DATA_DEFAULT_SUCCESS';
export const DATA_DEFAULT_FAIL = 'DATA_DEFAULT_FAIL';

// BOOKING
export const INIT_BOOKING_DETAIL = 'INIT_BOOKING_DETAIL';
export const BOOKING_DETAIL = 'BOOKING_DETAIL';
// export const SET_BOOKING_DETAIL = 'SET_BOOKING_DETAIL';
// export const SET_BOOKING_LIST_ROUTER = 'SET_BOOKING_LIST_ROUTER';

//FILTER
export const SET_FILTER_PARAM = 'SET_FILTER_PARAM';

//RECEIPT DELIVERY
export const SET_RECEIPT_DELIVERY_DETAIL = 'SET_RECEIPT_DELIVERY_DETAIL';
export const SET_RECEIPT_DELIVERY_LIST_DETAIL = 'SET_RECEIPT_DELIVERY_LIST_DETAIL';

//BREADCRUMB
export const BREADCRUMB_INI = 'BREADCRUMB_INI';
export const BREADCRUMB_CHANGE = 'BREADCRUMB_CHANGE';
// export const BREADCRUMB_CHANGE_DESC = 'BREADCRUMB_CHANGE_DESC';
export const LOCAL_STORAGE_BREADCRUMB = 'PLNYTYOHJT';

/*new*/
//AUTH
export const AUTH_USER_ID = "y44uiiX1y9";

//GridController
export const DATA_TABLE_INI = 'DATA_TABLE_INI';
export const DATA_TABLE_LOADING = 'DATA_TABLE_LOADING';
export const DATA_TABLE_SUCCESS = 'DATA_TABLE_SUCCESS';
export const DATA_TABLE_UPDATE_PAGING = 'DATA_TABLE_UPDATE_PAGING';
export const DATA_TABLE_FAIL = 'DATA_TABLE_FAIL'; 

//UserController
export const USER_INI = 'USER_INI';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_LOADING = 'USER_LOADING';
export const USER_FAIL = 'USER_FAIL';

export const LIST_USER_SUPPG_INI = 'LIST_USER_SUPPG_INI';
export const LIST_USER_SUPPG_SUCCESS = 'LIST_USER_SUPPG_SUCCESS';
export const LIST_USER_SUPPG_LOADING = 'LIST_USER_SUPPG_LOADING';
export const LIST_USER_SUPPG_FAIL = 'LIST_USER_SUPPG_FAIL';

//StoreController
export const STORE_INI = 'STORE_INI';
export const STORE_SUCCESS = 'STORE_SUCCESS';
export const STORE_LOADING = 'STORE_LOADING';
export const STORE_FAIL = 'STORE_FAIL';

//ProvinceController
export const LIST_PROVINCE_INI = 'LIST_PROVINCE_INI';
export const LIST_PROVINCE_SUCCESS = 'LIST_PROVINCE_SUCCESS';
export const LIST_PROVINCE_LOADING = 'LIST_PROVINCE_LOADING';
export const LIST_PROVINCE_FAIL = 'LIST_PROVINCE_FAIL';

//DataController
export const DATA_DETAIL_INI = 'DATA_DETAIL_INI';
export const DATA_DETAIL_SUCCESS = 'DATA_DETAIL_SUCCESS';
export const DATA_DETAIL_LOADING = 'DATA_DETAIL_LOADING';
export const DATA_DETAIL_FAIL = 'DATA_DETAIL_FAIL';

export const DATA_LIST_BY_STORE_INI = 'DATA_LIST_BY_STORE_INI';
export const DATA_LIST_BY_STORE_SUCCESS = 'DATA_LIST_BY_STORE_SUCCESS';
export const DATA_LIST_BY_STORE_LOADING = 'DATA_LIST_BY_STORE_LOADING';
export const DATA_LIST_BY_STORE_FAIL = 'DATA_LIST_BY_STORE_FAIL';

export const CUSTOMER_DETAIL_INI = 'CUSTOMER_DETAIL_INI';
export const CUSTOMER_DETAIL_SUCCESS = 'CUSTOMER_DETAIL_SUCCESS';
export const CUSTOMER_DETAIL_LOADING = 'CUSTOMER_DETAIL_LOADING';
export const CUSTOMER_DETAIL_FAIL = 'CUSTOMER_DETAIL_FAIL';


