import * as types from '../contains/actionTypes';
import {
    CREATE_STORE,
    UPDATE_STORE,
    DETAIL_STORE,
    DEL_STORE
} from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';
import * as config from '../../common/config';


import {
    axiosWithHeaders
} from '../contains/axiosWrapper';

import {
    show_error_init, 
    show_error
} from './error';

import {
    LoadDatatable
} from './index'

import {
    configTable,
    filterTable    
} from '../../common/configGrid/configStore';


export const store_init = () => {
    return {
        type: types.STORE_INI
    };
};

export const store_success = (detail: any) => {
    return {
        type: types.STORE_SUCCESS,
        detail
    }
}

export const store_loading = (loading: boolean) => {
    return {
        type: types.STORE_LOADING,
        loading
    }
}

export const store_fail = (message) => {
    return {
        type: types.STORE_FAIL,
        message
    }
}

export function GetDetailStore(storeId: number) {    
    // console.log(storeId)
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(store_init())
        dispatch(store_loading(true));
        axiosWithHeaders('POST', DETAIL_STORE, {
            storeId: storeId
        })
            .then((data:any) => {
                console.log(data)
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {status, result, message} = response;
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            console.log(result)
                            dispatch(store_success(result));
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Không thể lấy thông tin store !"))
                }
                dispatch(store_loading(false));
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(store_loading(false));
            });
    }
}

export function CreateStore(obj: any, history: any) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(store_loading(true));        
        axiosWithHeaders('POST',CREATE_STORE, obj)
        .then((data:any) => {
            console.log(data)
            if(data!==undefined && data!==null && Object.keys(data).length > 0){
                let response = data.data;
                let {status, message} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        // dispatch(user_success(null));
                        history.push(router.ListStore)
                        dispatch(show_error("success", "Thông báo", "Thêm store thành công !"))
                        break;
                    default:
                        dispatch(show_error("error", "Lỗi", message))
                        break;
                }
            }else{
                dispatch(show_error("error", "Lỗi", "Không thể tạo store !"))
            }
            dispatch(store_loading(false));
        }).catch(err => {
            console.log(err);
            dispatch(show_error("error", "Lỗi", err.toString()))
            dispatch(store_loading(false));
        }).finally(()=>{
            setTimeout(()=>{
                dispatch(show_error_init());
            }, config.DEFAULT_TIME_OUT)
        })
    }
}

export function UpdateStore(obj: any, history: any) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(store_loading(true));
        axiosWithHeaders('POST',UPDATE_STORE, obj)
        .then((data:any) => {
            console.log(data)
            if(data!==undefined && data!==null && Object.keys(data).length > 0){
                let response = data.data;
                let {status, message} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        dispatch(store_success(null));
                        dispatch(show_error("success", "Thông báo", "Cập nhật store thành công !"))
                        history.push(router.ListStore)
                        break;
                    default:
                        dispatch(show_error("error", "Lỗi", message))
                        break;
                }
            }else{
                dispatch(show_error("error", "Lỗi", "Không thể cập nhật store !"))
            }
            dispatch(store_loading(false));
        }).catch(err => {
            console.log(err);
            dispatch(show_error("error", "Lỗi", err.toString()))
            dispatch(store_loading(false));
        }).finally(()=>{
            setTimeout(()=>{
                dispatch(show_error_init());
            }, config.DEFAULT_TIME_OUT)
        })
    }
}

export function BackButtonStore(history: any) {    
    return async dispatch => {
        dispatch(store_success(null));
        history.push(router.ListStore)
    }
}

export function DeleteStore(obj: any, pageNo: number, pageRows: number) {    
    return async dispatch => {
        // datadefault_ini()
        dispatch(show_error_init());
        dispatch(store_loading(true));
        axiosWithHeaders('POST', DEL_STORE, obj)
        .then((data:any) => {
            console.log(data)
            if(data!==undefined && data!==null && Object.keys(data).length > 0){
                let response = data.data;
                let {status, message} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        dispatch(show_error("success", "Thông báo", "Xóa store thành công !"))
                        dispatch(LoadDatatable(configTable, filterTable, pageNo, pageRows, false))
                        break;
                    default:
                        dispatch(show_error("error", "Lỗi", message))
                        break;
                }
            }else{
                dispatch(show_error("error", "Lỗi", "Không thể xóa tin store !"))
            }
            dispatch(store_loading(false));
        }).catch(err => {
            console.log(err);
            dispatch(show_error("error", "Lỗi", err.toString()))
            dispatch(store_loading(false));
        }).finally(()=>{
            setTimeout(()=>{
                dispatch(show_error_init());
            }, config.DEFAULT_TIME_OUT)
        })
    }
}