import {GetURL,GetURLReport} from '../../common/config';

export const LOGIN = `${GetURL()}/api/account/login`;

export const REFRESHTOKEN = `${GetURL()}/api/refresh-token`;

export const UPLOAD_SINGlE_FILE = `${GetURL()}/api/single`;

//warehouse
export const WAREHOUSE = `${GetURL()}/api/listwarehouse`;

//customer
export const CUSTOMER = `${GetURL()}/api/listcustomer`;

//position in warehouse
export const ROUTERBYWAREHOUSE = `${GetURL()}/api/listroutewarehouse`;

//data default
export const LISTDATADEFAULT = `${GetURL()}/api/listdatadefault`;

//booking
export const ADDBOOKING = `${GetURL()}/api/booking`;
export const UPDATEBOOKING = `${GetURL()}/api/updatebooking`;

export const FINISHBOOKING = `${GetURL()}/api/finishbooking`;
//DATA
export const LIST_DATA = `${GetURL()}/api/listdata`;
export const DETAIL_DATA = `${GetURL()}/api/detaildata`;
export const DETAIL_DATA_SHIFT = `${GetURL()}/api/detaildatashift`;
export const DETAIL_DATA_ID_UNGQUA = `${GetURL()}/api/detaildataidungqua`;
export const DETAIL_DATA_ID_SPECIAL = `${GetURL()}/api/detaildataidspecial`;
export const DETAIL_DATA_ID_BANNER = `${GetURL()}/api/detaildatabanner`;
// export const DEL_IMAGE_DETAIL_DATA = `${GetURL()}/api/delimage`;
export const DATA_UPDATE_INFOMATION = `${GetURL()}/api/updateinfomation`;
export const DATA_POST_INFOMATION = `${GetURL()}/api/postinfomation`;
export const DATA_POST_INFOMATION_WEB = `${GetURL()}/api/webpostinfomation`;
export const DATA_POST_INFOMATION_ORDER_WEB = `${GetURL()}/api/postinfomationorderweb`;
export const DEL_DATA = `${GetURL()}/api/deldata`;
export const DATA_SPECIAL_LIST_DATA = `${GetURL()}/api/listdetailspecial`;
export const DATA_INSERT_IMAGE_GROUP = `${GetURL()}/api/insertimagegroup`;
export const DATA_UPDATE_INFO_LOCATION_GROUP = `${GetURL()}/api/updateinfolocation`;
export const DATA_UPDATE_INFO_GIFT = `${GetURL()}/api/dataupdategift`;

export const DEL_CUSTOMER = `${GetURL()}/api/deldatacustomer`;

export const DEL_INFOGROUP_DATA = `${GetURL()}/api/deldatainfogroup`;






// Store User

export const LIST_STOREUSER = `${GetURL()}/api/liststoreuser`;
export const UPDATE_STORE_USER = `${GetURL()}/api/storeuser/update-store-user`;
export const GET_ROUTE = `${GetURL()}/api/detailroute`;
export const DEL_STORE_USER = `${GetURL()}/api/delstoreuser`;


export const LIST_STORE = `${GetURL()}/api/liststore`;
export const LIST_STORE_BY_USERID = `${GetURL()}/api/store/list-store-user`;
export const LIST_STORE_FULL = `${GetURL()}/api/liststorefull`;
// export const ADD_STORE = `${GetURL()}/api/addstore`;
// export const GET_STORE= `${GetURL()}/api/getstore`;
// export const UPDATE_STORE = `${GetURL()}/api/updatestore`;
// export const DEL_STORE = `${GetURL()}/api/delstore`;
export const UPDATE_COST_STORE = `${GetURL()}/api/updatecost`;

//fileupload
export const FILE_UPLOAD = `http://file.tengroup.com.vn/api/file/upload`;
export const FILE_UPLOAD_LOCALHOST = `http://file.tengroup.com.vn/api/file/upload`;
export const FILE_UPLOAD_MULT = `http://file.tengroup.com.vn/api/file/uploadlist`;
export const FILE_UPLOAD_MULT_LOCALHOST = `http://localhost:50391/api/file/uploadlist`;


// Report 

export const REPORT_ALL = `${GetURL()}/api/report/reportall`;


export const VIEW_REPORT_PLAN_STOCK= `${GetURL()}/api/reportplanstock`;
export const VIEW_REPORT_SUMARY_1= `${GetURL()}/api/reportsumary1`;
export const VIEW_REPORT_SUMARY_3= `${GetURL()}/api/reportsumary3`;
export const VIEW_REPORT_SUMARY_2= `${GetURL()}/api/reportsumary2`;





//Gift
export const LIST_GIFT = `${GetURL()}/api/listgift`;
export const ADD_GIFT = `${GetURL()}/api/addgift`;
export const GET_GIFT= `${GetURL()}/api/getgift`;
export const UPDATE_GIFT = `${GetURL()}/api/updategift`;
export const DEL_GIFT = `${GetURL()}/api/delgift`;

// Shift
export const LIST_SHIFT = `${GetURL()}/api/listshift`;

// Master plan
export const LIST_MASTER_PLAN =     `${GetURL()}/api/listmasterplan`;
export const ADD_MASTER_PLAN =      `${GetURL()}/api/addmasterplan`;
export const GET_MASTER_PLAN=       `${GetURL()}/api/getmasterplan`;
export const UPDATE_MASTER_PLAN =   `${GetURL()}/api/updatemasterplan`;
export const DEL_MASTER_PLAN =      `${GetURL()}/api/delmasterplan`;

//CUstomer

export const GET_CUSTOMER_DETAIL =      `${GetURL()}/api/datacustomerdetail`;
export const UPDATE_CUSTOMER_ORDER =      `${GetURL()}/api/updatecustomerorder`;

// Province

export const LIST_PROVINCE_STORE =      `${GetURL()}/api/listprovincestore`;
export const LIST_DEFAULT_STORE_SUMARY =      `${GetURL()}/api/listdefaultsumary2`;

/*update grid*/
export const LOAD_DATATABLE = `${GetURL()}/api/grid/load-datatable`;


//UserController
export const CREATE_USER = `${GetURL()}/api/user/create-user`;
export const DETAIL_USER = `${GetURL()}/api/user/detail`;
export const UPDATE_USER = `${GetURL()}/api/user/update`;
export const DEL_USER = `${GetURL()}/api/user/del`;
export const LIST_USER_SUPPG = `${GetURL()}/api/user/listusersuppg`;
export const CHANGE_PASSWORD = `${GetURL()}/api/changepassword`;

// export const LIST_PROVINCE = `${GetURL()}/api/listprovince`;
export const LIST_USER_AUDIT = `${GetURL()}/api/user/listuseraudit`;
export const LIST_USER_ROLE = `${GetURL()}/api/listuserrole`;
export const LIST_USER_BY_STOREID = `${GetURL()}/api/listuserbystoreId`;

//StoreController
export const CREATE_STORE = `${GetURL()}/api/store/create-store`;
export const DETAIL_STORE = `${GetURL()}/api/store/detail-store`;
export const UPDATE_STORE = `${GetURL()}/api/store/update-store`;
export const DEL_STORE = `${GetURL()}/api/store/del-store`;

//ProvinceController
export const LIST_PROVINCE = `${GetURL()}/api/province/list`;

//DataController
export const DATA_LIST_BY_STORE = `${GetURL()}/api/data/data-list-by-store`;
export const DATA_DETAIL = `${GetURL()}/api/data/data-detail`;
export const DEL_IMAGE_DETAIL_DATA = `${GetURL()}/api/data/del-data-image`;
export const DATA_INSERT_IMAGE = `${GetURL()}/api/data/data-insert-web`;
export const CUSTOMER_INSERT_IMAGE_WEB = `${GetURL()}/api/data/customer-insert-image-web`;
export const CUSTOMER_INSERT_WEB = `${GetURL()}/api/data/customer-insert_web`;
export const CUSTOMER_UPDATE_WEB = `${GetURL()}/api/data/customer-update-web`;
export const DEL_DATA_IMAGE_CUSTOMER = `${GetURL()}/api/data/del-data-image-customer`;
export const DATA_CUSTOMER_DEL = `${GetURL()}/api/data/data-customer-del`;
export const DATA_DEL = `${GetURL()}/api/data/data-del`;
export const CUSTOMER_DETAIL = `${GetURL()}/api/data/customer-detail`;




