import * as router from './router';

export const dataMenu = (roleId)=>{
    let obj=[
        {
            groupName: "Chuyển hướng",
            items:[
                {
                    title: "Trang chủ",
                    description: "Trang chủ",
                    icon: "ik ik-home",
                    href: router.Home,
                    parentIndex: 0,
                    menuIndex: 1
                }
            ]
        }
    ];
   
    return obj;
} 
export const dataMenus = [
    {
		groupName: "Quản lý",
		items: [
			{
				title: "Tài khoản",
				description: "Danh sách tài khoản",
				icon: "ik ik-truck",
				href: router.ListUser,
                menuIndex: 2,
                parentIndex: 0,
				listRole: [1,2]
            },
            {
				title: "Danh sách cửa hàng",
				description: "Danh sách cửa hàng",
				icon: "ik ik-package",
                href: router.ListStore,
                menuIndex: 3,
                parentIndex: 0,
				listRole: [1,2]
            },
            {
                title: "Danh sách cửa hàng - user",
                description: "Danh sách cửa hàng - user",
                icon: "ik ik-user-check",
                href: router.ListStoreUser,
                menuIndex: 3,
                parentIndex: 0,
				listRole: [1,2]
            },
            {
				title: "Danh sách thông tin",
				description: "Danh sách thông tin",
				icon: "ik ik-package",
				href: router.ListData,
                menuIndex: 8,
                parentIndex: 0,
				listRole: [1,2,3]
            },            
            {
                title: "Download Report",
                description: "Report",
                icon: "ik ik-shopping-bag",
                href: router.Report,
				menuIndex: 9,
				parentIndex: 0,
				listRole: [1, 2,3]
			},
		],
		listRole: [1, 2, 3]
	}
	
]





export const filterUser = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnUser = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'code',
        header: 'Mã NV',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'name',
        header: 'Nhân viên',
        width: 100,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'address',
        header: 'Địa chỉ',
        width: 300,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'username',
        header: 'Tài khoản',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'phone',
        header: 'Di dộng',
        width: 70,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'roleName',
        header: 'Quyền truy cập',
        width: 100,
        align: 'left',
        fontSize: 11
    }, {
        field: 'action',
        header: '',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
]



export const listRole = (roleId)=>{
    if(roleId === 1)
   return  [
        {
            value: 2,
            label: "Moderator"
        },
        {
            value: 3,
            label: "Client"
        },
        {
            value: 4,
            label: "SUP"
        },
        {
            value: 5,
            label: "PG"
        }
    ]
    return  [
        {
            value: 4,
            label: "SUP"
        },
        {
            value: 5,
            label: "PG"
        }
    ]
} 
