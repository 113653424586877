import * as React from 'react';
import { DeferredContent } from 'primereact/deferredcontent';
import Error from './showError';
const layoutLogin = (props) =>{
    let url = '/login.jpg'
    return (
        <div style={{height:'100%',width:'100%',display:'flex' 
        //,backgroundImage : `url(${url})` 
        ,  backgroundSize:'cover'
                         , backgroundRepeat:'no-repeat' }}>
            <div className='p-col-12' style={{display:'flex'  }}>
            {props.children}
            </div>
           
            <Error />
        </div>
    )
}

export default layoutLogin;