export const configTable = {
    gridTitle: "Danh sách cửa hàng",
    storeProcedure: "Store_List",
    isInsert: true,
    isUpdate: true,
    isDelete: true
}

export let filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        typeField: 12,
        value: '',
        placeholder: 'Tìm kiếm theo...'
    }
];

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'storeCode',
        header: 'Mã cửa hàng',
        width: 120,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'storeName',
        header: 'Tên cửa hàng',
        width: 300,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'chain',
        header: 'Hệ thống',
        width: 120,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'storeAddress',
        header: 'Địa chỉ cửa hàng',
        width: "auto",
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'provinceName',
        header: 'Tỉnh thành',
        width: "auto",
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'action',
        // header: '#',
        width: 60,
        align: 'left',
        visibled: false,
        usedTemplate: true
    },
]