import * as React from 'react';
import * as types from '../reducer/contains/actionTypes'
import { connect } from 'react-redux';
import * as router from '../common/router'

import {
    withRouter,
    Link
} from "react-router-dom";

const PageHeader = (props: any) =>{
    const {
        breadcrumbOld,
        location
    } = props;

    const [breadcrumb, setbreadcrumb] = React.useState<any>(null);

    React.useEffect(()=>{
        if(location.pathname.includes("list")){
            setbreadcrumb(breadcrumbOld);    
        }else{
            setbreadcrumb(null);
        }
        // console.log(location.pathname.includes("list"))
    }, [location.pathname])

    const renderBreadCrumb = React.useMemo(()=>{
        // let breadcrumb: any = breadcrumbOld;

        // if(breadcrumb !== undefined || breadcrumb!==null || Object.keys(breadcrumb).length > 0){
        //     breadcrumb = breadcrumbOld;
        // }

        console.log(breadcrumb);

        if(breadcrumb!==undefined && breadcrumb!==null && Object.keys(breadcrumb).length > 0){
            const {
                title, 
                icon, 
                href, 
                description
            } = breadcrumb;

            return(
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-8">
                            <div className="page-header-title">
                                <i className={ icon + " bg-blue"}></i>
                                <div className="d-inline">
                                    <h5>{title}</h5>
                                    <span>{description}</span>
                                </div>
                            </div>
                        </div>
                        {
                            (title!=="Trang chủ") && (
                            <>
                            <div className="col-lg-4">
                                <nav className="breadcrumb-container" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to={router.Home}>
                                                <i className="ik ik-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link to={href}>
                                                <i className={icon}></i> {" " + title}
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            </>
                            )
                        }
                        
                    </div>
                </div>
            )
        }
        return null;
    }, [breadcrumb])

    return (
        <React.Fragment>
            {renderBreadCrumb}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        breadcrumbOld: state.breadcrumb.item,
        // breadcrumbNew: state.breadcrumb.itemNew
    }
}

export default connect(mapStateToProps, {})(withRouter(PageHeader));
