import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    detail: null,    
    loading: false,
    message: "",
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.USER_INI:
            return {
                ...state
            };
        case actionTypes.USER_SUCCESS:
            return {
                ...state,
                detail: action.detail,
            };
        case actionTypes.USER_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case actionTypes.USER_INI:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
}

export default reducer;