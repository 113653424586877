import * as types from '../contains/actionTypes';

export function set_filter_param(objFilter){
    return {
        type: types.SET_FILTER_PARAM,
        objFilter
    }
}

export function setFilterParam(objFilter){
    return async dispatch => {
        dispatch(set_filter_param(objFilter))
    }
}