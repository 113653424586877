const _ = require("lodash")
export const listQuestion = [
    {
        "questionId":1,
        "questionName":"Check-in tại booth",
        "questionType":2
    },{
        "questionId":2,
        "questionName":"Check-in tại quầy thu ngân",
        "questionType":2
    },{
        "questionId":3,
        "questionName":"Hình ảnh hoạt động",
        "questionType":2
    },{
        "questionId":4,
        "questionName":"Check-out tại booth",
        "questionType":2
    },{ 
        "questionId":5,
        "questionName":"Check-out tại quầy thu ngân",
        "questionType":2
    },{
        "questionId":6,
        "questionName":"Tên khách hàng",
        "questionType":3
    },{
        "questionId":7,
        "questionName":"Số điện thoại khách hàng",
        "questionType":5
    },{
        "questionId":8,
        "questionName":"Số lốc đăng ký",
        "questionType":4
    },{
        "questionId":9,
        "questionName":"Số thùng đăng ký",
        "questionType":4
    },{
        "questionId":10,
        "questionName":"Số lốc mua",
        "questionType":4
    },{
        "questionId":11,
        "questionName":"Số thùng mua",
        "questionType":4
    },{
        "questionId":12,
        "questionName":"Hộp quà lớn",
        "questionType":4
    },{
        "questionId":13,
        "questionName":"Hộp quà nhỏ",
        "questionType":4
    },{
        "questionId":14,
        "questionName":"Hình ảnh bill",
        "questionType":2
    },{
        "questionId":15,
        "questionName":"Hình tặng quà",
        "questionType":2
    },{
        "questionId":16,
        "questionName":"Số chai samlping",
        "questionType":4
    },{
        "questionId":17,
        "questionName":"Số ly nhựa",
        "questionType":4
    }
]

export const dataStatus = [
    // {
    //     value: 0,
    //     label: 'Chọn loại khách hàng'
    // },
    {
        value: 1,
        label: 'Khách hàng tiềm năng'
    },
    {
        value: 2,
        label: 'Khách hàng mua'
    }
]

export const getNameQuestion = (questionId: any) =>{
    let questionName = "";
    const itemQuestion = _.filter(listQuestion, (item: any)=>{
        return item.questionId === parseInt(questionId);
    });

    if(itemQuestion.length > 0){
        questionName = itemQuestion[0].questionName
    }

    return questionName;
}


export const getTypeQuestion = (questionId: any) =>{
    let questionType: number = 0;
    const itemQuestion = _.filter(listQuestion, (item: any)=>{
        return item.questionId === parseInt(questionId);
    });

    if(itemQuestion.length > 0){
        questionType = itemQuestion[0].questionType
    }

    return questionType;
}

