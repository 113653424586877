import * as actionTypes from '../contains/actionTypes';
import {GetBreadCrumb} from '../actions/breadcrumb'

const initialState: any = {
    item: GetBreadCrumb(),
    message: ""
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.BREADCRUMB_INI:
            return {
                ...state
            };
        case actionTypes.BREADCRUMB_CHANGE:
            return {
                ...state,
                item: action.item
            };
        default:
            return state;
    }
}

export default reducer;