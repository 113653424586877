import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    result: null,
    loading: false,
    message: "",
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.CUSTOMER_DETAIL_INI:
            return {
                ...state
            };
        case actionTypes.CUSTOMER_DETAIL_SUCCESS:
            return {
                ...state,
                result: action.result
            };
        case actionTypes.CUSTOMER_DETAIL_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case actionTypes.CUSTOMER_DETAIL_FAIL:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
}

export default reducer;