import '../assets/css/font-awesome/css/font-awesome.css';
import '../assets/css/icon-kit/dist/css/iconkit.min.css';
import '../assets/css/ionicons/dist/css/ionicons.min.css';
import '../assets/css/style.css';
import '../assets/css/custom/customize.css';
import '../assets/css/custom/responsive.scss';
import 'primereact/resources/themes/rhea/theme.css';
import React, { useState } from 'react';
import Header from './header/header';
import SideBar from './sidebar/index';
import PageHeader from './pageHeader';
import Error from './showError';
const Layout = (props: any) => {
    const wrapper = React.useRef<any>(null);
    return (
        <div  className="wrapper nav-collapsed menu-collapsed" 
        style={{top: 0, position:"absolute"}}
        ref={wrapper} >
            <Header  />            
         
            <div className="page-wrap">
                <SideBar 
                    wrapper={wrapper}
                />
                <div className="main-content">
                    <div className="row">
                        <div className="container-fluid">
                            <PageHeader />
                        </div>
                    </div>
                    <div style={{position:'relative'}}>
                        {
                            props.children
                        }
                    </div>
                  
                </div>
            </div>
            <Error />
        </div>
    )
}

export default Layout;