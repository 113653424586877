import React, {useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Button } from "@blueprintjs/core";

import { 
    // listQuestion,
    getNameQuestion
} from '../../common/configData';

import {
    groupBy
} from '../../common/function';

import GroupImage from './GroupImage'
import ModalCustomerInfo from './ModalCustomerInfo'
import * as actions from '../../reducer/actions/index';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum';

import Swal from 'sweetalert2';

const _ = require("lodash");
const TableInfoImage = (props: any) =>{
    const {
        title,
        data,
        listImage,
        storeId, 
        reloadPage,
        showError,
        GetCustomerDetail,
        ResetCustomerDetail
    } = props;

    const [isShow, setIsShow] = useState<boolean>(false);
    // const [rows, setRows] = useState<any>(null);

    const editRow = (item: any) =>{
        setIsShow(true)
        GetCustomerDetail(item[0].customerId)
    }

    const renderButton =(item: any)=>{
        if(props.profile.roleId ===1 || props.profile.roleId === 2){
            return (
                <div className='col-12' style={{position:'relative'}}>
                    <Button 
                        icon="edit" 
                        intent="primary" 
                        small 
                        text="SỬA"
                        style={{marginLeft: 5, position:'absolute', top: -94, right: 92}} 
                        onClick={(e: any) => editRow(item)}
                    /> 

                    <Button 
                        icon="trash" 
                        intent="danger" 
                        small 
                        text="XÓA"
                        style={{marginLeft: 5, position:'absolute', top: -94, right: 15}} 
                        onClick={(e: any) => delCustomer(item)}
                    /> 
                </div>
            )
        }
        return null;
    }

    const onChangeShow = () =>{
        setIsShow(false);
        ResetCustomerDetail();
    }

    const delCustomer = (item: any) => {
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa khách hàng khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                const objDel = {
                    customerId: item[0].customerId
                }

                axiosWithHeaders('POST', `${api.DATA_CUSTOMER_DEL}`, objDel)
                .then((response: any) => {
                    const {status, message } = response.data;
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            showError('success', 'Thông báo', "Xóa khách hàng thành công !");
                            reloadPage();                            
                            break;
                        default:
                            showError('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                }).catch(err => console.log(err));
            }
        })
    }

    const renderChild = (item: any, customerId: any) =>{
        let images = _.cloneDeep(listImage);
        let listimage = _.filter(images, (item: any) => {
            return item.customerId === parseInt(customerId);
        });

        let html: any = [];
        html.push(            
            <div className="row" key={999}>
                <div className="col-12">
                    <DataTable value={item}>
                        <Column field="nameCustomer" header="Tên khách hàng"></Column>
                        <Column field="phoneCustomer" header="Số điện thoại"></Column>
                        <Column field="info8" header={getNameQuestion(8)}></Column>
                        <Column field="info9" header={getNameQuestion(9)}></Column>
                        <Column field="info10" header={getNameQuestion(10)}></Column>
                        <Column field="info11" header={getNameQuestion(11)}></Column>
                        <Column field="info12" header={getNameQuestion(12)}></Column>
                        <Column field="info13" header={getNameQuestion(13)}></Column>
                    </DataTable>
                </div>
                {
                    renderButton(item)
                }
            </div>
       )

       if(listimage.length > 0){
            let listQuestions: any = groupBy(listimage, 'questionId', 'questionId', 'questions');
            listQuestions.forEach((item: any, index: number)=>{
                html.push(
                    <GroupImage 
                        title={<small>{getNameQuestion(item.questionId).toUpperCase()}</small>} 
                        data={item.questions} 
                        key={index} 
                        questionId={item.questionId} 
                        storeId={storeId} 
                        reloadPage={reloadPage}
                        customerId={customerId}
                        isMinium={true}
                    />
                )
            })

       }

       return (
            <React.Fragment>
                <Fieldset legend={<small>{title.toUpperCase()}</small>} toggleable={true} className="booking-custom table-info-image">
                    {html}
                </Fieldset>            
            </React.Fragment>
        );
    }

    const renderTable = useMemo(()=>{
        let html: any = []
        if(data!==undefined && data!== null && data.length > 0){
            let listGroup: any = groupBy(data, 'customerId', 'customerId', 'child'); 
            listGroup.forEach((item: any, index: number) =>{
                html.push(
                    <React.Fragment key={index}>
                        {renderChild(item.child, item.customerId)}
                    </React.Fragment>
                )
            })
        }
        return html;
    }, [data, listImage])

    return (
        <React.Fragment>
            <Fieldset legend="Danh sách khách hàng" toggleable={true} className="fieldset-group-image booking-custom">
                {renderTable}
            </Fieldset>
            <ModalCustomerInfo 
                // storeId={storeId} 
                customerStatus={2}
                isShow={isShow}
                onChangeShow={onChangeShow}
                reloadPage={reloadPage} 
                // reloadPage={() => ReloadPage(0)}
            />
        </React.Fragment>
    );
}


const mapStateToProps = (state:any) => {
    return {
        profile:state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showError: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail)),
        GetCustomerDetail: (customerId: number) => dispatch(actions.CustomerDetail(customerId)),
        ResetCustomerDetail: () => dispatch(actions.ResetCustomerDetail()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableInfoImage);
