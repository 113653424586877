
import * as React from 'react';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './reducer/reducers/storereducer';
import { withRouter } from 'react-router-dom';
import RootComponent from './RootComponent'
import LoadingView from './components/loading/loading';

function App(props: any) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RootComponent />
      </PersistGate>
    </Provider>
  );
}

export default withRouter(App);
