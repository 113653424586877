import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import { axiosWithHeaders,axiosWithHeadersNoTokenUploadFile,axiosWithHeadersDownloadFile } from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import TableThuDoi from './TableThuDoi'
import TableBanner from './TableBanner'
import TableUngQua from './TableUngQua'
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
 var _ = require('lodash');

const AddData = (props: any) => {
    const [storeId, setstoreId] = React.useState(0);
    const [code, setCode] = React.useState('');
    const [note, setNote] = React.useState('');
    const [dateSendData, setDateQuestion1] = React.useState<Date>(new Date());
    const [dateCheckIn, setDateCheckIn] = React.useState<Date>(new Date());
    const [dateCheckOut, setDateCheckOut] = React.useState<Date>(new Date());
    const [lng, setLng] = React.useState<any>(0);
    const [lat, setLat] = React.useState<any>(0);
    const [shiftId, setShiftId] = React.useState(0);
    const [userId, setUserId] = React.useState(0);
    const [roleId, setRoleId] = React.useState(0);
    const [locationCheckIn, setLocationCheckIn] = React.useState('');
    const [locationCheckout, setLocationCheckOut] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [checkin, setCheckin] = React.useState(1);
    const [listSup, setListSup] = React.useState([]);
    const [listPG, setListPG] = React.useState([]);
    const [listFile, setListFile] = React.useState([]) as any;
    const [listStore, setListStore] = React.useState([]);
    const [giftMask, setgiftMask] = React.useState(0);
    const [giftKhan, setgiftKhan] = React.useState(0);
    const [giftTho, setgiftTho] = React.useState(0);
    React.useEffect(() => {  
            LoadStore();
           // LoadUser();
    }, []);


    const LoadStore=()=>{
        axiosWithHeaders('GET', `${api.LIST_STORE_FULL}`, null)
        .then((data: any) => {
            if (data !== undefined && data !== null) {
                let response = data.data;
                let { status, message, result } = response;

                if (status === Status.STATUS_RESPONSE_OK) {
                    setListStore(result);
                }
                else {
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else { props.show_error('error', 'Lỗi', "Có lỗi xảy ra !!!"); }

        }).catch(err => {
            props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const LoadUser = (storeId :any) => {
        axiosWithHeaders('POST', api.LIST_USER_BY_STOREID,{storeId:storeId})
            .then((response: any) => {
                if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                 
                    let { status, message, result } = response.data;
                    switch (status) {
                        case Status.STATUS_RESPONSE_OK:
                       
                           let sup = _.filter(result, function(o) { return o.roleId === 4; })
                           let pg =_.filter(result, function(o) { return o.roleId === 5; })
                           // console.log(thudoi,ungqua)
                            setListSup(sup);
                            setListPG(pg);
                            break;
                        case Status.STATUS_RESPONSE_FORBIDDEN:
                            props.logOut();
                            break;
                        default:
                            props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                } else {
                    props.show_error('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
                }
            }).catch(err => {
                console.log(err);
                props.show_error('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            });
    }
  
  
    const SaveData =()=>{
        if( dateSendData === undefined || dateSendData ===null)
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn thời gian" );
            return false;
        }
        if(roleId === 5 && locationCheckIn !== '' && (dateCheckIn === undefined || dateCheckIn === null))
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn thời gian check in" );
        }
        if(roleId === 5 && locationCheckout !== '' && (dateCheckOut === undefined || dateCheckOut === null))
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn thời gian check in" );
        }
        if( userId === undefined || userId ===0)
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn tài khoản" );
            return false;
        }
        if( shiftId === 0)
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn đúng thời gian và đúng ca" );
            return false;
        }
        let listData = checkin  === 1 ? [{
            questionId:7,
            answer :locationCheckIn,
            dateCreate:moment(dateCheckIn).format('YYYY-MM-DD HH:mm:ss.SSS') 
        
        }] : [{
            questionId:8,
            answer :locationCheckout,
            dateCreate:moment(dateCheckOut).format('YYYY-MM-DD HH:mm:ss.SSS') 
            
        }]
        let obj = {
            storeId
            ,lng
            ,lat
            ,roleId:roleId
            ,dateSend:moment(dateSendData).format('YYYY-MM-DD HH:mm:ss.SSS') 
            ,userId
            ,code
            ,note
            ,shiftId
            ,giftTho
            ,giftMask
            ,giftKhan
            ,infogroup:moment().valueOf()
            ,list: listData,
            // [{
            //     questionId:7,
            //     answer :locationCheckIn,
            //     dateCreate:moment(dateCheckIn).format('YYYY-MM-DD HH:mm:ss.SSS') 
            
            // },
            // {
            //     questionId:8,
            //     answer :locationCheckout,
            //     dateCreate:moment(dateCheckOut).format('YYYY-MM-DD HH:mm:ss.SSS') 
                
            // }
            // ],
            listFile
        }
        axiosWithHeaders('POST', api.DATA_POST_INFOMATION_WEB,obj)
        .then((response: any) => {
            if (response !== undefined && response !== null && Object.keys(response).length > 0) {
                let { status, message } = response.data;
                switch (status) {
                    case Status.STATUS_RESPONSE_OK:
                        props.history.push(router.ListData);
                        break;
                    case Status.STATUS_RESPONSE_FORBIDDEN:
                        props.logOut();
                        break;
                    default:
                        props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                        break;
                }
            } else {
                props.show_error('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
            }
        }).catch(err => {
            console.log(err);
            props.show_error('error', 'Lỗi', "Hệ thống đang xảy ra lỗi ");
        });

    }
    const convertImageto64 = (file, item) => {
        var f = new FormData();
        f.append("file", file)
        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD}`, f)
            .then((data: any) => {
                if (data !== undefined && data.data.filePath !== undefined) {
                    var obj = {
                        questionId: item.questionId,
                        answer: data.data.filePath,
                        dateCreate: dateSendData
                    }
                    let list = listFile;
                    list.push(obj);
                    setListFile(list)
                }
            }).catch(err => console.log(err));
    }

    const onChangeFile = (event, item) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
            fileList.map((image: any) => {
                convertImageto64(image, item)
            });
        }
    }

    const onChangeStore=(value:any)=>{
        setstoreId(value);
        LoadUser(value);
    }
    const onValueChange = (e, field) => {
        let value = e.value !== undefined && e.value !== null ? e.value : 0
        switch(field){
            case 'giftMask':
                setgiftMask(value);
                break;
            case 'giftTho':
                setgiftTho(value);
                break;
            case 'giftKhan':
                setgiftKhan(value);
                break;
            default:
                break;
        }
    }
  
    return (
        <div>
            <FormLayout formTitle="THÊM MỚI THÔNG TIN">
                    <div className='p-col-12'>
                        <div className='row p-col-12'>
                            <label className="p-col-4">Thời gian thực hiện</label>
                            <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateSendData}  dateFormat="dd/mm/yy" touchUI
                                onChange={(e: any) => setDateQuestion1(e.value)}></Calendar>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Chọn Siêu Thị</label>
                            </div>
                            <div className="p-col-6">
                                <Dropdown 
                                    
                                    value={storeId} 
                                
                                    name="storeName"
                                    optionLabel="storeName" 
                                    optionValue="storeId" 
                                    options={listStore} 
                                    placeholder="Chọn siêu thị..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="storeId,storeName"
                                                        
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> onChangeStore(e.target.value)}
                                />
                
                            </div>
                        </div>
                        <div className="row p-col-12">
                           
                           <label className="p-col-4">CA</label>
                         
                           <div className="p-col-6">
                               <Dropdown 
                                   value={shiftId} 
                                   optionLabel="shiftName" 
                                   optionValue="shiftId" 
                                   options={[{"shiftId":1,"shiftName":"AM"},{"shiftId":2,"shiftName":"PM"}]} 
                                   placeholder="Chọn Ca thực hiện..." 
                                   filter={true} 
                                   filterPlaceholder="Tìm..." 
                                   filterBy="shiftId,shiftName"
                                   // filterMatchMode="contains"
                                   style={{width : '100%'}} 
                                   onChange={(e: any)=> setShiftId(e.value)}
                               />
                           </div>
                       </div>
                        <div className="row p-col-12">
                           
                           <label className="p-col-4">Role Nhân Viên</label>
                         
                           <div className="p-col-6">
                               <Dropdown 
                                   value={roleId} 
                                   optionLabel="roleName" 
                                   optionValue="roleId" 
                                   options={[{"roleId":4,"roleName":"SUP"},{"roleId":5,"roleName":"PG"}]} 
                                   placeholder="Chọn Role nhân viên..." 
                                   filter={true} 
                                   filterPlaceholder="Tìm..." 
                                   filterBy="roleId,roleName"
                                   // filterMatchMode="contains"
                                   style={{width : '100%'}} 
                                   onChange={(e: any)=> setRoleId(e.value)}
                               />
                           </div>
                       </div>
                        <div className="row p-col-12">
                            <label className="p-col-4">Nhân viên audit</label>
                            <div className="p-col-6">
                                <Dropdown 
                                    value={userId} 
                                    optionLabel="name" 
                                    optionValue="userId" 
                                    options={roleId === 4 ? listSup : listPG} 
                                    placeholder="Chọn user audit..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="userId,name"
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> setUserId(e.value)}
                                />
                            </div>
                        </div>
                   
                        <div className="row p-col-12">
                           
                           <label className="p-col-4">Data Gửi lên</label>
                         
                           <div className="p-col-6">
                               <Dropdown 
                                   value={checkin} 
                                   optionLabel="Name" 
                                   optionValue="checkin" 
                                   options={[{"checkin":1,"Name":"CHECK IN"},{"checkin":2,"Name":"CHECK OUT"}]} 
                                   placeholder="Chọn Gửi lên Data Checkin hay Checkout" 
                                //    filter={true} 
                                //    filterPlaceholder="Tìm..." 
                                //    filterBy="checkin,Name"
                                   // filterMatchMode="contains"
                                   style={{width : '100%'}} 
                                   onChange={(e: any)=> setCheckin(e.value)}
                               />
                           </div>
                        </div>
                        {roleId !== 4 && roleId !== 5 ? <></> :<>
                        {roleId === 5? <>
                            {checkin === 1 ? <>
                            <div className='row p-col-12'>
                                <div className="p-col-4">
                                    <label>Định vị checkin</label>
                                </div>
                                <div className="p-col-6">
                                    <InputText value={locationCheckIn} placeholder="Vui lòng nhập Định vị..."
                                        style={{ width: '100%' }} onChange={(e:any) => setLocationCheckIn(e.target.value)} />
                                </div>
                            </div>
                            <div className='row p-col-12'>
                            <label className="p-col-4">Thời gian check in</label>
                            <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateCheckIn}  dateFormat="dd/mm/yy" touchUI
                                onChange={(e: any) => setDateCheckIn(e.value)}></Calendar>
                            </div>
                            <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Mặt Nạ</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={giftMask} placeholder="Số lượng mặt nạ" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'giftMask')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Khăn giấy tẩy trang</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={giftKhan} placeholder="Số lượng khăn giấy tẩy trang" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'giftKhan')}   />
                                  
                            </div>
                        </div>
                        <div className="p-grid" style={{ marginTop: -7 }}>
                            <div className="p-col-4 text-right">
                                <label>Số lượng Thố thủy tinh</label>
                            </div>
                            <div className="p-col-2">
                                <InputNumber   max={400000000} value={giftTho} placeholder="Số lượng thố thủy tinh" 
                                    style={{ width: '100%',textAlign: 'right' }} onChange={(e) => onValueChange(e,'giftTho')}   />
                                  
                            </div>
                        </div>
                            <div className='row p-col-12'>
                                <div className='row p-col-12'>
                                    <label className="p-col-4">Hình ảnh check in</label>
                                    <input type="file"
                                        name="import1"
                                        className="p-col-4" multiple accept="image/*"
                                        onChange={(event) => onChangeFile(event, { questionId: 4 })}
                                    />

                                </div>

                            </div>

                            </> : <>
                            <div className='row p-col-12'>
                                <div className="p-col-4">
                                    <label>Định vị Check out</label>
                                </div>
                                <div className="p-col-6">
                                    <InputText value={locationCheckout} placeholder="Vui lòng nhập Định vị..."
                                        style={{ width: '100%' }} onChange={(e:any) => setLocationCheckOut(e.target.value)} />
                                </div>
                            </div>
                            <div className='row p-col-12'>
                            <label className="p-col-4">Thời gian checkout</label>
                            <Calendar className="p-col-3" showTime={true} hourFormat="24" value={dateCheckOut}  dateFormat="dd/mm/yy" touchUI
                                onChange={(e: any) => setDateCheckOut(e.value)}></Calendar>
                            </div>
                         
                            <div className='row p-col-12'>
                                <div className='row p-col-12'>
                                    <label className="p-col-4">Hình ảnh checkout</label>
                                    <input type="file"
                                        name="import2"
                                        className="p-col-4" multiple accept="image/*"
                                        onChange={(event) => onChangeFile(event, { questionId: 5 })}
                                    />

                                </div>
                            </div>
                            </>}
                            </>
                            :
                            <>
                        <div className='row p-col-12'>
                            <div className='row p-col-12'>
                                <label className="p-col-4">Hình ảnh nhân sự </label>
                                <input type="file"
                                    name="import2"
                                    className="p-col-4" multiple accept="image/*"
                                    onChange={(event) => onChangeFile(event, { questionId: 1 })}
                                />

                            </div>
                        </div>
                          <div className='row p-col-12'>
                            <div className='row p-col-12'>
                                <label className="p-col-4">Hình ảnh booth </label>
                                <input type="file"
                                    name="import2"
                                    className="p-col-4" multiple accept="image/*"
                                    onChange={(event) => onChangeFile(event, { questionId: 2 })}
                                />

                            </div>
                        </div>
                        <div className='row p-col-12'>
                            <div className='row p-col-12'>
                                <label className="p-col-4">Hình ảnh hoạt động </label>
                                <input type="file"
                                    name="import2"
                                    className="p-col-4" multiple accept="image/*"
                                    onChange={(event) => onChangeFile(event, { questionId: 3 })}
                                />

                            </div>
                        </div>
                        </>
                        }
                        </>
                    }
                    </div>
                    <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                        <div className='p-col-4'> 
                        </div>
                        <div className="p-col-2">
                                <Link className="bp3-button bp3-large btn-block btn-back" to={router.ListData}>QUAY LẠI</Link>
                            </div>
                            <div className="p-col-2">
                               <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>
                              
                           
                            </div>
                            <div className='p-col-4'> 
                        </div>
                        </div>
                           
                      
            </FormLayout>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        show_error: (severity, summary, detail) => dispatch(actions.show_error(severity, summary, detail))
    }
}
const mapStateToProps = (state:any) => {
    return {
      profile:state.auth.profile
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(AddData);
