import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { groupBy, chunkArray } from '../../common/function';
import { getNameQuestion, getTypeQuestion } from '../../common/configData';

import GroupImage from './GroupImage';
import GroupInfo from './GroupInfo';
import TableInfo from './TableInfo';
import TableInfoImage from './TableInfoImage';

const ContentTabs = (props: any) =>{
    const {
        dataId,
        datadetail,
        listCustomer,
        listCustomerSearch,
        listImageCustomer,
        tableReport,
        GetDataDetail,
        storeId,
        reloadPage,
        dateSend
    } = props;

    useEffect(()=>{
        GetDataDetail(dataId);
    }, [dataId]);

    // console.log(dateSend)

    const reloadData = () => {
        reloadPage();
        GetDataDetail(dataId)
    }

    // console.log(listCustomer, listImageCustomer)

    const renderDataDetail = useMemo(()=>{
        let html: any = [];
        if(datadetail.length > 0){
            let listQuestions: any = groupBy(datadetail, 'questionId', 'questionId', 'questions');
            // console.log(listQuestions);
            listQuestions.forEach((item: any, index: number)=>{
                switch(getTypeQuestion(item.questionId)){
                    case 2:
                        html.push(
                            <GroupImage 
                                title={getNameQuestion(item.questionId)} 
                                data={item.questions} 
                                key={index} 
                                reloadPage={reloadData} 
                                storeId={storeId} 
                                questionId={item.questionId}
                                dateSend={dateSend}
                            />
                        )
                        break;
                    default:
                        break;
                }
                
            })
        }

        if(listCustomer!== undefined && listCustomer!==null && Array.from(listCustomer).length > 0){
            html.push(
                <TableInfoImage 
                    title="Khách hàng" 
                    data={listCustomer} 
                    listImage={listImageCustomer} 
                    key={997} 
                    reloadPage={reloadData} 
                    storeId={storeId} 
                    dateSend={dateSend}
                />
            )
        }

        if(listCustomerSearch!== undefined && listCustomerSearch!==null && Array.from(listCustomerSearch).length > 0){
            html.push(
                <TableInfo 
                    title="Danh sách khách hàng tiềm năng" 
                    data={listCustomerSearch} 
                    reloadPage={reloadData} 
                    key={998}
                />
            )
        }

        if(tableReport!== undefined && tableReport!==null && Object.keys(tableReport).length > 0){
            html.push(
                <GroupInfo 
                    title="Báo cáo tổng" 
                    data={tableReport} 
                    key={999}
                />
            )
        }
        return html;
    }, [datadetail, tableReport])

    return (
        <React.Fragment>
            {renderDataDetail}
        </React.Fragment>
    );
}


const mapStateToProps = (state:any) => {
    // console.log(state)
    return {
        datadetail: state.datadetail.result,
        listCustomer: state.datadetail.listCustomer,
        listCustomerSearch: state.datadetail.listCustomerSearch,
        listImageCustomer: state.datadetail.listImageCustomer,
        tableReport: state.datadetail.tableReport,
        loading: state.datadetail.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        GetDataDetail: (dataId: number) => dispatch(actions.GetDataDetail(dataId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTabs);
