
import * as React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import Layout from './layout/layout';
import LayoutLogin from './layout/layoutLogin';

import * as router from './common/router';

import Home from "./pages/Home/Home";
// import BBGiaoNhanHangHoa from "./pages/BBGNHH";

import Page404 from './pages/404'


// import Login from './components/login/style1';
// import Login2 from './components/login/style2PureComponent'
import Login2Hooks from './pages/Login/style2Hook'
//User
import ListUser from './pages/User/ListUser';
import User from './pages/User/User';
// Store
import ListStore from './pages/Store/ListStore'
import Store from './pages/Store/Store';

// StoreUser
import ListStoreUser from './pages/StoreUser/ListStoreUser'
import StoreUser from './pages/StoreUser/StoreUser';

//DATA
import ListData from './pages/Data/ListData';
import Data from './pages/Data/Data';
import DataOrder from './pages/Data/DataOrder' ;
import UpdateCustomerOrder from './pages/Data/UpdateCustomerOrder'
import AddData from './pages/Data/AddData'

function RootComponent(props: any) {
  let route = (
    <LayoutLogin>
      <Switch>
        <Route path={router.Login} component={Login2Hooks} />
        <Redirect to={router.Login} />
      </Switch>
    </LayoutLogin>
  );

  if(props.isAuth){
    route = (
      <Layout>
        <Switch>
            <Route exact path={router.Home} component={Home} />
            <Route path={router.Page404} component={Page404} />
            <Redirect to={router.Home} />
        </Switch>
      </Layout>
    );

    const{roleId} = props.profile;

    switch(roleId){
      case 1:
        route = (
          <Layout>
            <Switch>
                <Route exact path={router.ListData} component={ListData} />
                <Route exact path={router.Report} component={Home} />
                <Route exact path={router.ListUser} component={ListUser} />
                <Route exact path={`${router.User}`} render={(props) => <User {...props} />}  />
                <Route exact path={`${router.User}/:id`} render={(props) => <User {...props} />}  />

                <Route exact path={router.ListStore} component={ListStore} />
                <Route exact path={`${router.Store}`} render={(props) => <Store {...props} />}  />
                <Route exact path={`${router.Store}/:id`} render={(props) => <Store {...props} />}  />



                <Route exact path={router.ListStoreUser} component={ListStoreUser} />
                <Route exact path={`${router.StoreUser}`} render={(props) => <StoreUser {...props} />}  />
                {/* <Route exact path={`${router.Store}`} render={(props) => <Store {...props} />}  />
                <Route exact path={`${router.Store}/:id`} render={(props) => <Store {...props} />}  /> */}

                <Route path={`${router.Data}/:storeId/`} render={(props) => <Data {...props} />}  />
                <Route  path={`${router.UpdateCustomerOrder}/:id/:store`} render={(props) => <UpdateCustomerOrder {...props} />}  />
                <Route  exact path={`${router.AddData}`} render={(props) => <AddData {...props} />}  />




                <Route path={router.Page404} component={Page404} />
                <Redirect to={router.ListData} />
            </Switch>
          </Layout>
        );
    
        break;
      case 2:
        route = (
          <Layout>
            <Switch>
                <Route exact path={router.Report} component={Home} />
                
                <Route exact path={router.ListUser} component={ListUser} />
                <Route exact path={`${router.User}`} render={(props) => <User {...props} />}  />
                <Route exact path={`${router.User}/:id`} render={(props) => <User {...props} />}  />

                <Route exact path={router.ListStoreUser} component={ListStoreUser} />
                <Route exact path={`${router.StoreUser}`} render={(props) => <StoreUser {...props} />}  />
                {/*
                <Route exact path={router.ListStore} component={ListStore} />
                <Route exact path={`${router.Store}`} render={(props) => <Store {...props} />}  />
                <Route exact path={`${router.Store}/:id`} render={(props) => <Store {...props} />}  /> */}

                <Route exact path={router.ListData} component={ListData} />
                <Route exact path={`${router.Data}/:id`} render={(props) => <Data {...props} />}  />


                <Route path={router.Page404} component={Page404} />
                <Redirect to={router.ListData} />
            </Switch>
          </Layout>
        );
      //  console.log(router);
        break;
      case 3:
        route = (
          <Layout>
            <Switch>
                 <Route exact path={router.Report} component={Home} />
                <Route exact path={router.ListData} component={ListData} />
                <Route path={`${router.Data}/:id/:date`} render={(props) => <Data {...props} />}  />
              
                <Route path={`${router.Data}/:storeId/`} render={(props) => <Data {...props} />}  />

                <Route path={router.Page404} component={Page404} />
                <Redirect to={router.ListData} />
                
            </Switch>
          </Layout>
        );
        break;
    }

  }
  return ( <>
     {route}
  </>
  
  );
}


const mapStateToProps = (state:any) => {
  //  console.log(store)
  return {
    isAuth: state.auth.profile !== null,
    profile:state.auth.profile
  }
}

export default connect(mapStateToProps, null)(withRouter(RootComponent));
