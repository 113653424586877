
export const Home = '/';
export const Login = '/login';
export const ReceiptDelivery = '/receiptDelivery';
export const Page404 = '/404';

export const ListBooking = '/listbooking';
export const Booking = '/booking'
// export const UpdateBooking = '/updatebooking'
export const DetailBooking = '/detailbooking'
export const ChooseVehicleDriver = '/choosebooking'


export const ListCustomer = '/listcustomer'
export const Customer = '/customer'


export const ListVehicle = '/listvehicle'
export const Vehicle = '/vehicle'

export const ListStoreUser= '/liststoreuser'
export const StoreUser= '/storeuser'


export const ListUser= '/listuser';
export const User = '/user'

export const ListData= '/listdata'
export const Data= '/data'
export const DataOrder= '/dataorder'
export const UpdateCustomerOrder= '/updateorder'
export const AddData= '/adddata'
export const AddDataOrder= '/adddataorder'

export const ListStore = '/liststore';
export const Store = '/store';

export const Report = '/report';


export const ListProduct = '/listproduct';
export const Product = '/product';

export const ListStoreProductPrice = '/liststoreproductprice';
export const StoreProductPrice = '/storeproductprice';

export const ListGift = '/listgift';
export const Gift = '/gift';

export const ListMasterPlan = '/listmasterplan';
export const MasterPlan = '/masterplan';

export const ReportViewPlanStock = '/reportplanstock';
export const ReportViewSumary1 = '/reportsumary1';
export const ReportViewSumary2 = '/reportsumary2';
export const ReportViewSumary3 = '/reportsumary3';





