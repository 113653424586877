import * as React from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import * as router from "../../common/router";
import {PickList} from 'primereact/picklist';
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import {ProgressSpinner} from 'primereact/progressspinner';
import * as Status from '../../common/enum'
 var _ = require('lodash');

const StoreUser = (props: any) => {
    const {profile}= props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [userId, setUserId] = React.useState(0 as number);
    const [listUser,   setListUser] = React.useState([]);
    const [dataStore,   setDataStore] = React.useState([]);
    const [dataStoreNow,   setDataStoreNow] = React.useState([]);
    const [listDistrict,   setListDistrict] = React.useState([]);
    const [district,   setDistrict] = React.useState('');
    const [dataStoreSelected,   setDataStoreSelected] = React.useState([]);
    const [keyword,   setKeyword] = React.useState('');
    React.useEffect(() => {
        GetListUser();
    }, []);
    const GetListStore=(userId :any)=>{
        axiosWithHeaders('POST',`${api.LIST_STORE_BY_USERID}`,{userId:userId,userCheck:profile.userId})
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                setIsLoading(false)
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                  
                    setListDistrict(result.districts)
                    if(result.list !== undefined  && result.list !== null )
                    {
                        setDataStore(result.list)
                        setDataStoreNow(result.list)
                    }
                    if(result.listSelected !== undefined  && result.listSelected !== null )
                    {
                        setDataStoreSelected(result.listSelected)     
                    }
                  
                }
                else{
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.show_error('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }
    const GetListUser=()=>{
        axiosWithHeaders('GET',`${api.LIST_USER_AUDIT}`,null)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                    setListUser(result)      
                }
                else{
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.show_error('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });

    }
    const onValueChange = (e) => {
        setIsLoading(true)
        setUserId(e.target.value)
        GetListStore(e.target.value);
    }
     const onChange = (event) => {
        if(userId === 0 || userId === null ){
            props.show_error('error', 'Lỗi', "Vui lòng chọn tài khoản" );
            return false;   
        }
   
        let list = event.target;
        let listdel = event.source;
        let obj ={userId, list}
       // console.log(obj)
        axiosWithHeaders('POST',api.UPDATE_STORE_USER, obj)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                  setIsLoading(true)
                 
                    // setDataStoreNow(event.source);
                    // setDataStoreSelected(event.target);
                    GetListStore(userId);
                    props.show_error('success', 'Thông báo', 'Thêm mới thành công !');
                }
                else{
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra ! !!!" + message);
                }
            }
            else{  props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" );}
            
        }).catch(err => {
            props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    };
    const areaTemplate=(store)=> {
        return (
          <div className="p-clearfix">            
            <div style={{ fontSize: '14px'}}>{store.storeCode} - {store.storeName} - {store.storeAddress} - {store.district}</div>
          </div>
        );
      }
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            ChangeDataFilter();
        }
      }
      const ChangeDataFilter=()=>{
        let listStoreNow  = dataStoreNow; 
            listStoreNow = _.filter(dataStore, function(o) {
                return ( o.storeCode.includes(keyword) || o.storeName.includes(keyword)); })

          //  console.log(listStoreNow)
            setDataStoreNow(listStoreNow);
      }
    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="p-grid">
               
                    <div className="p-col-12">
                        <div className="p-grid" style={{paddingBottom:'20px'}}>
                            <div className="p-col-4 text-right">
                                <label>Tài khoản</label>
                            </div>
                            <div className="p-col-6">
                                <Dropdown 
                                    value={userId} 
                                    optionLabel="name" 
                                    optionValue="userId" 
                                    options={listUser} 
                                    placeholder="Chọn Tài khoản..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="userId,name"
                                    // filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> onValueChange(e)}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-2 text-right">
                                <label>Tìm kiếm</label>
                            </div>
                            <div className="p-col-2">
                                <InputText
                                    placeholder="Nhập tên cửa hàng"
                                    value={keyword}
                                    onChange={(e: any)=> setKeyword(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    className="filter-item"
                                    style={{width: "100%"}}
                                />
                            </div>
                            <div className="p-col-3">
                                    <Button
                                    icon="search"
                                    onClick={ChangeDataFilter}
                                >
                                    Tìm kiếm
                                </Button>
                            </div>
                        </div>
                        <div className="p-grid">
                        <div className="p-col-12">
                      
                            {isLoading ? <ProgressSpinner style={{margin:'auto', display:'flex'}} /> : null}
                        </div>
                            <div className="p-col-12">
                            <PickList 
                                    source={dataStoreNow} 
                                    target={dataStoreSelected} 
                                    itemTemplate={areaTemplate}
                                    sourceHeader="Danh sách cửa hàng" 
                                    targetHeader="Danh sách cửa hàng thuộc tài khoản" 
                                    onChange={onChange}
                                    // responsive={true}
                                    sourceStyle={{height: '600px'}} 
                                    targetStyle={{height: '600px'}}
                                >
                                </PickList>
                            </div>
                        </div>
                        <div className="p-grid" style={{marginBottom: 10, marginTop: 10}}>
                            <div className="p-col-6">
                                <Link className="bp3-button bp3-large bp3-intent-primary btn-block btn-back" to={router.ListStoreUser}>QUAY LẠI</Link>
                            </div>
                        </div>
                    </div>
                
                </div>
            </FormLayout>
        </div>
    )
    return null;
}

const mapStateToProps = state => {
    return {
        profile: state.auth.profile
    }
}
const mapDispatchToProps = dispatch => {
    return {
        show_error: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreUser);
