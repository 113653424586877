import React, {useRef} from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';

const Error = (props:any) =>{
    let toast = useRef<any>(null);
    React.useEffect(() => {
        if(props.error.severity !== null)
        {
            const{ severity, summary, detail} = props.error;
            toast.current.show({severity: severity, summary: summary, detail: detail});
        }
        
    });
  
    return <Toast ref={toast} style={{paddingTop:70}} />
    
}

const mapStateToProps = state => {
  
    return {
        error: state.error
    }
}

export default connect(mapStateToProps, null)(Error);
