import * as types from '../contains/actionTypes';
import {
    LIST_USER_SUPPG,
} from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';

import {
    axiosWithHeaders
} from '../contains/axiosWrapper';

import {
    show_error_init, 
    show_error
} from './error';

export const list_user_suppg_init = () => {
    return {
        type: types.LIST_USER_SUPPG_INI
    };
};

export const list_user_suppg_success = (result: any) => {
    return {
        type: types.LIST_USER_SUPPG_SUCCESS,
        result
    }
}

export const list_user_suppg_loading = (loading: boolean) => {
    return {
        type: types.LIST_USER_SUPPG_LOADING,
        loading
    }
}

export const list_user_suppg_fail = (message) => {
    return {
        type: types.LIST_USER_SUPPG_FAIL,
        message
    }
}

export function ListUserSuppg(storeId: number) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(list_user_suppg_init())
        dispatch(list_user_suppg_loading(true));
        axiosWithHeaders('GET', `${LIST_USER_SUPPG}?storeId=${storeId}`, null)
            .then((data:any) => {
                // console.log(data)
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {
                        status, 
                        message,
                        result
                    } = response;
                    // console.log(response);
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            dispatch(list_user_suppg_success(result));
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Không thể lấy danh sách sup-pg !"))
                }
                dispatch(list_user_suppg_loading(false));
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(list_user_suppg_loading(false));
            });
    }
}

export function BackButtonData(history: any) {    
    return async dispatch => {
        dispatch(list_user_suppg_init());
        history.push(router.ListData)
    }
}
