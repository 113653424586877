import React from 'react';
import { Button, Card, Elevation } from "@blueprintjs/core";
import { connect } from 'react-redux';

const FormLayout = (props: any) => {
    const {formName, formTitle, showAdd, onClickAdd, profile} = props;
    const renderButton = (formName) => {
        switch(formName){
            case 'user':
                if(profile.roleId === 1 || profile.roleId === 2){
                    return (
                        <Button text="THÊM MỚI" icon="plus" style={{marginRight: 10}} onClick={(e: any)=> onClickAdd(e)}/>
                    )
                }
                return null;
            // case 'route':
            //     return null;
            // case 'user':
            //     return (
            //         <Button text="THÊM MỚI" icon="plus" style={{marginRight: 10}} onClick={(e: any)=> onClickAdd(e)}/>
            //     )
            default:
                if(profile.roleId === 1 || profile.roleId === 2){
                    return (
                        <Button text="THÊM MỚI" icon="plus" style={{marginRight: 10}} onClick={(e: any)=> onClickAdd(e)}/>
                    );
                }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{marginBottom: 20}}>
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-9 col-xl-9">
                            <h5>{formTitle.toUpperCase()}</h5>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 text-right">
                            {(showAdd)&&renderButton(formName)}
                        </div>
                    </div>
                    { props.children }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile
    }
}

export default connect(mapStateToProps, {})(FormLayout);


// export default FormLayout;

// class FormLayout extends React.Component<any, any>{
//     constructor(props){
//         super(props)
//         this.state={
//             formTitle: '',
//             hideButton: true,
//             isHideContent: false
//         }
//     }

//     public componentDidMount(){
//         let {formTitle} = this.props;
//         // console.log(this.props, this.state);
//         this.setState({
//             formTitle: formTitle
//         });

//         let actionToggle = document.querySelector(".action-toggle");
//         let minimizeCard = document.querySelector(".minimize-card");
//         let closeCard = document.querySelector(".close-card");

//         (actionToggle as HTMLPictureElement).addEventListener("click", (e: any)=> this.toggleButton(e));
//         (minimizeCard as HTMLPictureElement).addEventListener("click", (e: any)=> this.minimizeCard(e));
//         (closeCard as HTMLPictureElement).addEventListener("click", (e: any)=> this.closeCard(e));

//     }

//     public componentWillUnmount(){
//         let actionToggle = document.querySelector(".action-toggle");
//         let minimizeCard = document.querySelector(".minimize-card");
//         let closeCard = document.querySelector(".close-card");

//         (actionToggle as HTMLPictureElement).removeEventListener("click", (e: any)=> this.toggleButton(e));
//         (minimizeCard as HTMLPictureElement).removeEventListener("click", (e: any)=> this.minimizeCard(e));
//         (closeCard as HTMLPictureElement).removeEventListener("click", (e: any)=> this.closeCard(e));
//     }

//     private toggleButton = (e: any) =>{
//         // console.log(e);
//         let {hideButton} = this.state;
//         // console.log(hideButton);
//         let cardOption = document.querySelector(".card-option");
//         let actionToggle = document.querySelector(".action-toggle");
//         if(!hideButton){
//             (cardOption as HTMLDivElement).style.width = "28px";
//             (actionToggle as HTMLPictureElement).classList.remove("ik-chevron-right");
//             (actionToggle as HTMLPictureElement).classList.add("ik-chevron-left");
//         }else{
//             (cardOption as HTMLDivElement).style.width = "75px";
//             (actionToggle as HTMLPictureElement).classList.remove("ik-chevron-left");
//             (actionToggle as HTMLPictureElement).classList.add("ik-chevron-right");
//         }

//         this.setState({
//             hideButton: !hideButton
//         })
//     }

//     private minimizeCard = (e: any) => {
//         let {isHideContent} = this.state;
//         let cardBody = document.querySelector(".card-body");
//         let minimizeIcon = document.querySelector(".minimize-card");
//         if(!isHideContent){
//             (cardBody as HTMLDivElement).classList.add("d-none");
//             (minimizeIcon as HTMLPictureElement).classList.remove("ik-minus");
//             (minimizeIcon as HTMLPictureElement).classList.add("ik-plus");
//         }else{
//             (cardBody as HTMLDivElement).classList.remove("d-none");
//             (minimizeIcon as HTMLPictureElement).classList.remove("ik-plus");
//             (minimizeIcon as HTMLPictureElement).classList.add("ik-minus");
//         }
//         this.setState({
//             isHideContent: !isHideContent
//         })
//     }

//     private closeCard = (e: any) =>{
//         let formLayout = document.querySelector(".form-layout");
//         formLayout?.remove();
//     }

//     public render(){

//         let {formTitle} = this.state;
//         let {children, showAdd, onClickAdd} = this.props;

//         return(
//             <div className="row">
//                 <div className="col-md-12 col-sm-12">
//                     <div className="card form-layout">
//                         <div className="card-header">
//                             <h3>{formTitle?.toUpperCase()}</h3>
//                             <div className="card-header-right">
//                                 {(showAdd)&&(
//                                     <Button text="THÊM MỚI" icon="plus" style={{marginRight: 10}} onClick={(e: any)=> onClickAdd(e)}/>
//                                 )}
//                                 <ul className="list-unstyled card-option d-none">
//                                     <li><i className="ik ik-chevron-left action-toggle"></i></li>
//                                     <li><i className="ik ik-minus minimize-card"></i></li>
//                                     <li><i className="ik ik-x close-card"></i></li>
//                                 </ul>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             {children}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// export default FormLayout as any;