import { combineReducers } from 'redux';
import auth from './auth';
import router from './router';
import datadefault from './datadefault';
import booking from './booking';
import filter from './filter';
import error from './error';
import breadcrumb from './breadcrumb';

//Cập nhật
import datatable from './datatable';
import user from './user';
import store from './store';
import datadetail from './datadetail'
import datalistbystore from './datalistbystore';
import listusersuppg from './listusersuppg';
import customerdetail from './customerdetail';


const reducers = combineReducers({
    auth,
    router,
    datadefault,
    booking,
    filter,
    error,
    breadcrumb,
    /*new*/
    datatable,
    user,
    store,
    datadetail,
    datalistbystore,
    listusersuppg,
    customerdetail
});

export default reducers;