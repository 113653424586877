import React, {useEffect, useState, useMemo} from 'react';
import FormLayout from '../../components/form/index';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { TabView, TabPanel } from 'primereact/tabview';
import * as router from "../../common/router";
// import * as api from '../../reducer/contains/api'
// import { 
//     axiosWithHeadersNoTokenUploadFile 
// } from '../../reducer/contains/axiosWrapper';
import { Button } from "@blueprintjs/core";
import ModalAddImage from './ModalAddImage';
import ModalCustomerInfo from './ModalCustomerInfo';
import ContentTab from './DataDetail';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'

import Swal from 'sweetalert2';

// import moment from 'moment';
var _ = require('lodash');

const Data = (props: any) => {
    const {
        detailStore,
        listTab,
        profile,
        GetDataListByStore,
        ListUserSuppg,
        showError,
        GetDataDetail
    } = props;

    const [storeId, setstoreId] = useState<number>(0);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    // const [dateSendData, setDateSendData] = useState<Date>(new Date());
    // const [listFile, setListFile] = useState<any>([]);
    /*update*/

    

    useEffect(() => {
        if (props.match.params.storeId !== undefined && props.match.params.storeId !== null) {
            setstoreId( props.match.params.storeId);
            GetDataListByStore(props.match.params.storeId);
            ListUserSuppg(props.match.params.storeId);
        }
        else {
            showError('error', 'Lỗi', "Có lỗi xảy ra !!!");
            props.history.push(router.ListData);
        }        
    }, []);

    const ReloadPage = (index: number) => { 
        setActiveIndex(index);
        GetDataListByStore(storeId);
        GetDataDetail(listTab[index].dataId)
    }

    const onTabChange =(e:any)=>{
        setActiveIndex(e.index)
        ReloadPage(e.index)
    }

    const delTab = (item: any) => {
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa tab " + item.dateSend + " ? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                const objDel = {
                    dataId: item.dataId
                }

                axiosWithHeaders('POST', `${api.DATA_DEL}`, objDel)
                .then((response: any) => {
                    const {status, message } = response.data;
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            ReloadPage(0)
                            showError('success', 'Thông báo', "Xóa tab thành công !");                            
                            break;
                        default:
                            showError('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                }).catch(err => console.log(err));
            }
        })
    }

    const renderButtonAdd = () => {
        if(profile!==undefined && profile!==null && Object.keys(profile).length>0){
            if(profile.roleId ===1 || profile.roleId === 2){
                return (
                    <React.Fragment>
                        <ModalCustomerInfo 
                            storeId={storeId} 
                            customerStatus={0}
                            isShow={false}
                            onChangeShow={null}
                            data={null}
                            reloadPage={() => ReloadPage(activeIndex)}
                        />
                        <ModalAddImage storeId={storeId} reloadPage={() => ReloadPage(activeIndex)}/>
                    </React.Fragment>
                )
            }
            return null;
        }
        return null;
    }

    const renderDetailStore = useMemo(()=>{
        if(detailStore!==undefined && detailStore!==null && Object.keys(detailStore).length > 0){
            return (
                <React.Fragment>
                    <div className="row" style={{paddingTop: 10}}>
                        <div className="col-6 align-self-center">
                            <span style={{color: "red", fontSize: "1.25rem",}}>
                                <Button 
                                    // intent=""
                                    style={{marginRight: 10}}
                                    icon="chevron-left"
                                    title="Quay về danh sách"
                                    onClick={()=>{props.history.push(router.ListData);}}
                                />{detailStore.storeName.toUpperCase()}</span>
                        </div>
                        <div className="col-6 text-right">
                            {renderButtonAdd()}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    }, [detailStore])

    

    const renderTabPanel = useMemo(()=>{
        let html: any = [];
        if(listTab!==undefined && listTab!==null && listTab.length > 0){
            listTab.forEach((item: any, index: number)=>{
                html.push(
                    <TabPanel header={item.dateSend} key={index} >
                        <div className="row">
                            <div className="col-12 text-center">
                                <Button 
                                    large 
                                    text={"XÓA TAB " + item.dateSend} 
                                    intent="danger"
                                    onClick={(e: any)=> delTab(item)}
                                />
                            </div>
                        </div>
                        <ContentTab 
                            dataId={item.dataId} 
                            reloadPage={()=>{
                                ReloadPage(index)
                            }} 
                            dateSend={item.dateSend}
                            storeId={storeId}
                        />
                    </TabPanel>
                )
            })
        }
        return html;
    }, [listTab]);

    return (
        <div>
            <FormLayout formTitle="CHI TIẾT CỬA HÀNG">
                {renderDetailStore}                
                <div className="row" style={{paddingTop: 10}}>
                    <div className="col-12">
                        <TabView className="tabview" activeIndex={activeIndex} onTabChange={(e) => onTabChange(e) }>                  
                            {renderTabPanel}             
                        </TabView>
                    </div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapStateToProps = (state: any) => {
    return {
        profile:state.auth.profile,
        detailStore: state.datalistbystore.detail,
        listTab: state.datalistbystore.result
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showError: (severity, summary, detail) => dispatch(actions.show_error(severity, summary, detail)),
        GetDataDetail: (dataId: number) => dispatch(actions.GetDataDetail(dataId)),
        GetDataListByStore: (storeId: number) => dispatch(actions.GetDataListByStore(storeId)),
        ListUserSuppg: (storeId: number) => dispatch(actions.ListUserSuppg(storeId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Data);
