import * as types from '../contains/actionTypes';
import {
    LOAD_DATATABLE
} from '../contains/api';
import * as Status from '../../common/enum';
import * as config from '../../common/config';

import {
    axiosWithHeaders
} from '../contains/axiosWrapper';
import {
    show_error_init, 
    show_error
} from './error';

export const data_table_init = () => {
    return {
        type: types.DATA_TABLE_INI
    };
};

export const data_table_success = (data: any) => {
    return {
        type: types.DATA_TABLE_SUCCESS,
        data
    }
}

export const data_table_loading = (loading: boolean) => {
    return {
        type: types.DATA_TABLE_LOADING,
        loading
    }
}

export const data_table_update_paging = (first: number, pageNo: number, pageRows: number, totalRecords: number) => {
    return {
        type: types.DATA_TABLE_UPDATE_PAGING,
        first,
        pageNo,
        pageRows,
        totalRecords
    }
}


export const data_table_fail = (message) => {
    return {
        type: types.DATA_TABLE_FAIL,
        message
    }
}


export function LoadDatatable(settingTable: any, filterTable: any, pageNo: number, pageSize: number, isNewFilter: boolean) {
    
    return async dispatch => {
        dispatch(show_error_init())
        dispatch(data_table_init())
        dispatch(data_table_loading(true));

        // console.log(settingTable, filterTable);

        if(settingTable === undefined || settingTable === null || Object.keys(settingTable).length === 0){
            return;
        }

        if(filterTable === undefined || filterTable === null || Array.from(filterTable).length === 0){
            return;
        }

        const {
            gridTitle,
            storeProcedure
        } = settingTable;

        let parameter: any = [];
        
        Array.from(filterTable).forEach((item: any)=>{
            parameter.push(
                {
                    Field: item.field,
                    TypeField: item.typeField,
                    Value: item.value
                }
            )
        })

        let indexUserId = parameter.findIndex(x=>x.Field === "userId");
        
        if (indexUserId > -1) {
            parameter.splice(indexUserId, 1);
        }

        parameter.push({
            Field: "userId",
            TypeField: 8,
            Value: localStorage.getItem(types.AUTH_USER_ID)
        })

        let indexPageNo = parameter.findIndex(x=>x.Field === "pageNo");
        
        if (indexPageNo > -1) {
            parameter.splice(indexPageNo, 1);
        }

        if(isNewFilter){
            pageNo = 0;
        }

        parameter.push({
            Field: "pageNo",
            TypeField: 8,
            Value: (pageNo + 1).toString()
        })

        let indexPageSize = parameter.findIndex(x=>x.Field === "pageRows");
        
        if (indexPageSize > -1) {
            parameter.splice(indexPageSize, 1);
        }

        parameter.push({
            Field: "pageRows",
            TypeField: 8,
            Value: pageSize.toString()
        })


        let objRespone = {
            Name: gridTitle,
            StoreProcedure: storeProcedure,
            Parameters: parameter
        }

        // console.log(JSON.stringify(objRespone));

        axiosWithHeaders('post',LOAD_DATATABLE, objRespone)
            .then((data: any) => { 
                if(data!==undefined && data!==null){                    
                    let response = data.data;
                    let {status, result, message} = response;
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            // console.log(result)
                            // datadefault_success(result);
                            if(result!==undefined && result!==null && Object.keys(result).length > 0){
                                let rowIndex: number = 1;
                                Array.from(result).forEach((item: any) => {
                                    item["stt"] = (pageNo * pageSize) + rowIndex;
                                    rowIndex ++;
                                })
                                let totalRecords = 0;
                                if (Array.from(result).length > 0) {
                                    totalRecords = result[0].countRow;
                                }
                                dispatch(data_table_success(result));
                                dispatch(data_table_update_paging(pageNo * pageSize, pageNo, pageSize, totalRecords));
                                dispatch(data_table_loading(false));
                            }
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Hệ thống [load-datatable] đang xảy ra lỗi... "))
                }
                
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(data_table_loading(false));
            }).finally(()=>{
                setTimeout(()=>{
                    dispatch(data_table_loading(false));
                }, config.DEFAULT_TIME_OUT)
            })
    }
}
