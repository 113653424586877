import * as types from '../contains/actionTypes';

export const breadcrumb_ini = () => {
    return {
        type: types.BREADCRUMB_INI
    };
};

export const breadcrumb_change = (item: any) => {
    // saveBreadcrumb(item);
    return {
        type: types.BREADCRUMB_CHANGE,
        item
    }
}

export function saveBreadcrumb(item: any){
    localStorage.setItem(types.LOCAL_STORAGE_BREADCRUMB, JSON.stringify(item));
}

export function GetBreadCrumb(){
    let item: any = null;
    item = localStorage.getItem(types.LOCAL_STORAGE_BREADCRUMB);
    if(item!==undefined && item!==null && item.length > 0){
        item = JSON.parse(item);
    }
    return item;
}