import React, {useMemo} from 'react';
import { InputText } from 'primereact/inputtext';
import { 
    Button
} from "@blueprintjs/core";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { chunkArray } from '../../common/function'
// import { header } from '../../reducer/contains/header';
const _ = require("lodash");

const FilterUpdate = (props: any) => {
    const {
        listFilter,
        onClickFilter,
        onChangeFilter
    } = props


    // React.useEffect(()=>{
    //     console.log(onClickFilter)
    // }, [])

    const renderItem = (item: any, idx: number) => {
        switch(item.typeField){
            case 12:
                return (
                    <div className="row">
                        <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5 text-right align-self-center">
                            <label>{item.title}</label>
                        </div>
                        <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                            <InputText
                                placeholder={item.placeholder}
                                value={item.defaultValue}
                                onChange={(e: any)=> onChangeFilter(e.target.value, idx)}
                                style={{width: "100%"}}
                            />
                        </div>
                    </div>
                )
            default:
                return null;
        }        
    }

    const renderFilter = useMemo(()=>{
        let html: any = []
        if(listFilter.length > 0){
            chunkArray(listFilter, 4).forEach((item: any, index: number) => {
                html.push(
                    <div key={index} className="row" style={{paddingTop: 20}}>
                        {item.map((items: any, idx: number) =>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-3 col-xxlarge-3" key={idx}>
                                {renderItem(items, idx)}
                            </div>
                        )}
                    </div>
                )
            });
        }
        return html;
    }, [listFilter])

    return (
        <div className="container-fluid">
            {renderFilter}
            <div className="row" style={{paddingTop: 20, marginBottom: 5}}>
                <div className="col-12 col-smaller-12 col-small-12 col-medium-12 col-large-12 col-xlarge-12 col-xxlarge-12 text-center">
                    <Button
                        icon="search"
                        onClick={onClickFilter}
                    >
                        Tìm kiếm
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FilterUpdate;