import React, {useEffect, useMemo, useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { Button } from "@blueprintjs/core";
import { Fieldset } from 'primereact/fieldset';
import { 
    getNameQuestion
} from '../../common/configData';
import ModalCustomerInfo from './ModalCustomerInfo';
import * as actions from '../../reducer/actions/index';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'

import Swal from 'sweetalert2';

const TableInfo = (props: any) =>{
    const {
        profile,
        title,
        data,
        reloadPage,
        showError,
        GetCustomerDetail,
        ResetCustomerDetail
    } = props;

    const [isShow, setIsShow] = useState<boolean>(false);

    const editRow = (item: any) =>{
        setIsShow(true)
        GetCustomerDetail(item.customerId);
    }

    const onChangeShow = () =>{
        setIsShow(false);
        ResetCustomerDetail();
    }

    const delCustomer = (item: any) => {
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa khách hàng tiềm năng này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                const objDel = {
                    customerId: item.customerId
                }

                axiosWithHeaders('POST', `${api.DATA_CUSTOMER_DEL}`, objDel)
                .then((response: any) => {
                    const {status, message } = response.data;
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                        //  console.log(data[0].dataId)
                            showError('success', 'Thông báo', "Xóa khách hàng tiềm năng thành công !");
                            reloadPage();                            
                            break;
                        default:
                            showError('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                            break;
                    }
                }).catch(err => console.log(err));
            }
        })
    }

    const renderItem  = (row: any, cell: any) =>{
        let {field} = cell;
        switch(field){            
            case 'action':
                return (
                    <>
                       <Button 
                            icon="edit" 
                            intent="primary" 
                            small 
                            title="Sửa"
                            style={{marginLeft: 5}} 
                            onClick={(e: any)=> editRow(row)}
                        />
                        <Button 
                            icon="trash" 
                            intent="danger" 
                            small 
                            title="Xóa"
                            style={{marginLeft: 5}} 
                            onClick={(e: any)=> delCustomer(row)}
                        />
                    </>
                )
            default:
                return null;
        }
    }

    const renderTable = useMemo(()=>{
        if((data!==undefined && data!== null && data.length > 0) &&
        (profile!==undefined && profile!==null && Object.keys(profile).length > 0)){
            return (
                <DataTable value={data}>
                    <Column field="nameCustomer" header="Tên khách hàng"></Column>
                    <Column field="phoneCustomer" header="Số điện thoại"></Column>
                    <Column field="info8" header={getNameQuestion(8)}></Column>
                    <Column field="info9" header={getNameQuestion(9)}></Column>
                    {
                        (profile.roleId === 1 || profile.roleId === 2)&&(
                            <Column field="action" body={renderItem} style={{width: 70}}></Column>
                        )
                    }
                </DataTable>
            )
        }
        return null;
    }, [data, profile])

    return (
        <React.Fragment>
            <Fieldset legend={title} toggleable={true} className="fieldset-group-image booking-custom">
                {renderTable}
                <ModalCustomerInfo 
                    customerStatus={1}
                    isShow={isShow}
                    onChangeShow={onChangeShow}
                    reloadPage={reloadPage} 
                 />
            </Fieldset>
        </React.Fragment>
    );
}


const mapStateToProps = (state:any) => {
    return {
        profile:state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showError: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail)),
        GetCustomerDetail: (customerId: number) => dispatch(actions.CustomerDetail(customerId)),
        ResetCustomerDetail: () => dispatch(actions.ResetCustomerDetail()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableInfo);
