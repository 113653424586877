import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    isLoading: false,
    listRouter: [],
    comboRouter: [],
    message: null 
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.ROUTER_INI:
            return {
                isLoading: false,
                listRouter: [],
                comboRouter: [],
                message: null 
            };
        case actionTypes.ROUTER_DATA_SUCCESS:
            return {
                ...state,
                listRouter: action.listRouter,
            };
        case actionTypes.ROUTER_DROPDOWN_SUCCESS:
            // console.log(action)
            return {
                ...state,
                comboRouter: action.comboRouter,
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            };
        case actionTypes.ROUTER_FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.message
            };
        default:
            return state;
    }
}

export default reducer;