import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    detail: null,
    listPosition: [],
    listCost: []
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.INIT_BOOKING_DETAIL:
            return {
                ...state,
                detail: null,
                listPosition: [],
                listCost: []
            };
        case actionTypes.BOOKING_DETAIL:
            console.log('====================================');
            console.log(action.detail);
            console.log('====================================');
                return {
                    ...state,
                    detail: action.detail
                };
        default:
            return state;
    }
}

export default reducer;