import * as React from 'react';
// import {Button} from "@blueprintjs/core";
import {InputText} from 'primereact/inputtext';
import { 
    Button
} from "@blueprintjs/core";
import {Dropdown} from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
// import { header } from '../../reducer/contains/header';
const _ = require("lodash");

class Filter extends React.Component<any, any>{
    constructor(props){
        super(props);
        this.state={
            filters: []
        }
    }

    public componentDidMount(){
        let {filters} = this.props;
        this.setState({
            filters: filters
        })
    }

    private onValueChange = (e: any, filterIndex: number) => {
        let {filters} = this.state;
        if(filters!==undefined && filters!== null && Array.from(filters).length > 0){
            // console.log(filters[filterIndex]);
            const {type} = filters[filterIndex];
            
            switch(type){
                case 'date':
                    filters[filterIndex].defaultValue = e.value;
                   // console.log(filters)
                    break;
                case 'dropdownparent':
                    filters[filterIndex].defaultValue = e.target.value;
                    filters.map((item,i)=>{
                       if( item.parent ===  filters[filterIndex].child && item.type=== 'dropdownitem')
                       {
                        filters[i].valueParent =  e.target.value
                       }
                    })
                    console.log(filters)
                   // filters[filterIndex].defaultValue = e.target.value;
                       // console.log(filters)
                        break;
                default:
                    filters[filterIndex].defaultValue = e.target.value;
                    break;
            }

            this.setState({
                filters: filters
            })
        }
    }
    private _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let {filters} = this.state;
            let {getFilterParam} = this.props;
            return getFilterParam(filters);
        }
      }
    private renderFilterItem(item: any, itemIndex: number){
        if(item!==undefined && item!== null && Object.keys(item).length > 0){
            switch(item.type){
                case 'string':
                    return (
                        <div className="row">
                            <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5 text-right">
                                <label style={{paddingTop: 9}} className="filter-label">{item.title}</label>
                            </div>
                            <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText
                                    placeholder={item.placeholder}
                                    value={item.defaultValue}
                                    onChange={(e: any)=> this.onValueChange(e, itemIndex)}
                                    onKeyDown={this._handleKeyDown}
                                    className="filter-item"
                                    style={{width: "100%"}}
                                />
                            </div>
                        </div>
                    );
                case 'date':
                    return (
                        <div className="row">
                            <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5">
                                <label style={{paddingTop: 9}} className="filter-label">{item.title}</label>
                            </div>
                            <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                {
                                    this.renderDateItem(item, itemIndex)
                                }
                            </div>
                        </div>
                    )
                case 'dropdown':
                    let {customizeDropdown} = this.props;
                  //   console.log(item)

                    return (
                        <div className="row">
                            <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5">
                                <label style={{paddingTop: 9}} className="filter-label">{item.title}</label>
                            </div>
                            <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <Dropdown 
                                    value={item.defaultValue} 
                                    options={item.data} 
                                    onChange={(e: any)=> this.onValueChange(e, itemIndex)} 
                                    placeholder={item.placeholder}
                                    style={{width: "100%"}}
                                   // itemTemplate={(typeof(customizeDropdown)==="function")? customizeDropdown: null}
                                  //  className="filter-item"
                                    optionLabel = {item.optionLabel}
                                    optionValue = {item.optionValue}
                                />
                            </div>
                        </div>
                    );
                case 'dropdownparent':
                        return (
                            <div className="row">
                                <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5">
                                    <label style={{paddingTop: 9}} className="filter-label">{item.title}</label>
                                </div>
                                <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                    <Dropdown 
                                        value={item.defaultValue} 
                                        options={item.data} 
                                        onChange={(e: any)=> this.onValueChange(e, itemIndex)} 
                                        placeholder={item.placeholder}
                                        style={{width: "100%"}}
                                       // itemTemplate={(typeof(customizeDropdown)==="function")? customizeDropdown: null}
                                      //  className="filter-item"
                                        optionLabel = {item.optionLabel}
                                        optionValue = {item.optionValue}
                                    />
                                </div>
                            </div>
                        );
                case 'dropdownitem':
                      return (
                            <div className="row">
                                <div className="col-5 col-smaller-5 col-small-5 col-medium-5 col-large-5 col-xlarge-5 col-xxlarge-5">
                                    <label style={{paddingTop: 9}} className="filter-label">{item.title}</label>
                                </div>
                                <div className="col-6 col-smaller-6 col-small-6 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                    <Dropdown 
                                        value={item.defaultValue} 
                                        options={_.filter(item.data, function(o) { return o[item.nameItem] === item.valueParent})} 
                                        onChange={(e: any)=> this.onValueChange(e, itemIndex)} 
                                        placeholder={item.placeholder}
                                        style={{width: "100%"}}
                                       // itemTemplate={(typeof(customizeDropdown)==="function")? customizeDropdown: null}
                                      //  className="filter-item"
                                        optionLabel = {item.optionLabel}
                                        optionValue = {item.optionValue}
                                    />
                                </div>
                            </div>
                        );
                default:
                    return null;
            }
        }
        // console.log(item);
        return null;
    }

    private renderDateItem(item: any, filterIndex: number){
        if(item!==undefined && item!== null && Object.keys(item).length > 0){
            let {filters} = this.state;
            switch(item.field){
                case 'dateFrom':
                    let maxDate: any = null;
                    if(filters!==undefined && filters!==null && Array.from(filters).length > 0){
                        let objDateTo: any = _.filter(filters, (o)=>{
                            return o.field === "dateTo";
                        });
                        
                        if(Array.from(objDateTo).length > 0){
                            maxDate = objDateTo[0].defaultValue;
                        }
                    }

                    return (
                        <Calendar 
                            value={item.defaultValue} 
                            onChange={(e: any)=> this.onValueChange(e, filterIndex)} 
                            maxDate={maxDate}
                            placeholder="Chọn từ ngày"
                            style={{width:"100%"}}
                            dateFormat="dd/mm/yy"
                            readOnlyInput
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="2018:2024"
                            className="filter-item"
                            >
                        </Calendar>
                    );
                case 'dateTo':
                    let minDate: any = null;
                    if(filters!==undefined && filters!==null && Array.from(filters).length > 0){
                        let objDateFrom: any = _.filter(filters, (o)=>{
                            return o.field === "dateFrom";
                        });

                        if(Array.from(objDateFrom).length > 0){
                            minDate = objDateFrom[0].defaultValue;
                        }
                    }

                    return (
                        <Calendar 
                            value={item.defaultValue} 
                            onChange={(e: any)=> this.onValueChange(e, filterIndex)} 
                            minDate={minDate}
                            placeholder="Chọn đến ngày"
                            style={{width:"100%"}}
                            dateFormat="dd/mm/yy"
                            readOnlyInput
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="2018:2024"
                            className="filter-item"
                        
                            >
                        </Calendar>
                    );
                default:
                    return null;
            }
            // return null;
        }
        return null;
    }

    private renderFilter(){
        let html: any = []
        const {filters} = this.state;
        if(filters!==undefined && filters!==null && Array.from(filters).length > 0){
            Array.from(filters).map((item, i)=>{
                return html.push(
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-3 col-xxlarge-3" key={i} style={{padding: 0, marginTop: 10}}>
                        {
                            this.renderFilterItem(item, i)
                        }
                    </div>
                )
            })
        }
        return html;
    }

    private clickFilterButton(e: any){
        e.preventDefault();
        let {filters} = this.state;
        let {getFilterParam} = this.props;
        return getFilterParam(filters);
    }

    public render(){
        // console.log(this.state)
        return (
            <div className="container-fluid">
                <div className="row">
                    {
                        this.renderFilter()
                    }
                </div>
                <div className="row" style={{marginTop: 20, marginBottom: 5}}>
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-12 col-large-12 col-xlarge-12 col-xxlarge-12 text-center">
                        <Button
                            icon="search"
                            onClick={(e: any)=> this.clickFilterButton(e)}
                        >
                            Tìm kiếm
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setFilterParam: (objFilter) => dispatch(actions.setFilterParam(objFilter))
    }
}

export default connect(null, mapDispatchToProps)(Filter);