import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    result: [],  
    listCustomer: [],
    listCustomerSearch: [], 
    listImageCustomer: [],
    tableReport: null,
    loading: false,
    message: "",
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.DATA_DETAIL_INI:
            return {
                ...state
            };
        case actionTypes.DATA_DETAIL_SUCCESS:
            return {
                ...state,
                result: action.result,
                listCustomer: action.listCustomer,
                listCustomerSearch: action.listCustomerSearch,
                listImageCustomer: action.listImageCustomer,
                tableReport: action.tableReport
            };
        case actionTypes.DATA_DETAIL_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case actionTypes.DATA_DETAIL_FAIL:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
}

export default reducer;