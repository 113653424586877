import * as actionTypes from '../contains/actionTypes';
import {deCryptToken,GetProfile,deCryptRefreshToken} from '../../authentication/crypto'
const initialState:any = {
   // token: deCryptToken(),
  //  refresh:  deCryptRefreshToken(),
    isLoading: false,
    message: null ,
    profile: GetProfile()
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.AUTH_INI:
            return {
              //  token: deCryptToken(),
              //  refresh:  deCryptRefreshToken(),
                profile:GetProfile(),
                isLoading: false,
                message: null 
            };
        case actionTypes.AUTH_LOGOUT:
                return {
                    token: null,
                    refresh:  null,
                    profile:null,
                    isLoading: false,
                    message: null 
                };
        case actionTypes.AUTH_SUCCESS:
            return {
              //  token: action.token,
                isLoading: false,
                message: null ,
                profile:action.profile
              //  refresh:action.refresh
            }
        case actionTypes.AUTH_RELOAD_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.AUTH_FAIL:
            return {
                token: null,
                profile:null,
                isLoading: false,
                message: action.message,
                refresh:null
            }
        default:
            return state;
    }
}

export default reducer;