export const configTable = {
    gridTitle: "Danh sách nhân viên",
    storeProcedure: "User_List",
    isInsert: true,
    isUpdate: true,
    isDelete: true
}

export let filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        typeField: 12,
        value: '',
        placeholder: 'Tìm kiếm theo...'
    }
];

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'name',
        header: 'Tên nhân viên',
        width: 250,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'username',
        header: 'Tài khoản',
        width: 250,
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'roleName',
        header: 'Quyền truy cập',
        width: "auto",
        align: 'left',
        visibled: false,
        usedTemplate: false
    },
    {
        field: 'action',
        // header: '#',
        width: 60,
        align: 'left',
        visibled: false,
        usedTemplate: true
    },
]