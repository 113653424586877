import React, {useState} from 'react';
import { 
    Menu, 
    MenuDivider, 
    MenuItem, 
    Popover, 
    Position,
    Dialog,
    Classes,
    Button
} from "@blueprintjs/core";
import {Password} from 'primereact/password';
import * as actions from '../../reducer/actions/index';
import { connect } from 'react-redux';
import * as Status from '../../common/enum'
import {
    CHANGE_PASSWORD
} from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import { 
    enCryptData, 
    // deCryptData 
} from '../../authentication/crypto';

const PageHeader = (props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [oldpassword, setoldpassword] = useState<string>("");
    const [password, setpassword] = useState<string>("");

    const menuHeader = (
        <Menu>
            {/* <MenuItem icon="user" text="Tài khoản" /> */}
            <MenuItem icon="unlock" text="Đổi mật khẩu" onClick={()=>{setShowDialog(true)}}/>
            {/* <MenuItem icon="notifications" text="Thông báo"/>
            <MenuItem icon="inbox" text="Tin nhắn" /> */}
            <MenuDivider />
            <MenuItem icon="power" text="Đăng xuất" onClick={()=>props.logOut()}/>
        </Menu>
    )

    const changePassword = () =>{        
        // console.log(obj);
        if(oldpassword===""){
            props.show_error("error", "Lỗi", "Vui lòng nhập mật khẩu cũ");
            return;
        }
        if(password===""){
            props.show_error("error", "Lỗi", "Vui lòng nhập mật khẩu mới");
            return;
        }
        props.show_error(null, null, null);
        const obj = {oldpassword, password}
        const data = enCryptData(obj);
        // console.log({data: data}, userId)
        axiosWithHeaders('POST', CHANGE_PASSWORD, {data: data})
        .then((response: any) => {
            if(response!==undefined && response!==null && Object.keys(response).length > 0){
                // let response = data;
                // console.log(response);
                let {status} = response;
                switch(status){
                    case Status.STATUS_RESPONSE_OK:
                        // Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                        props.show_error("success", "Thông báo", "Đổi mật khẩu thành công");
                        setoldpassword("");
                        setpassword("");
                        setShowDialog(false);
                        break;
                    case Status.STATUS_RESPONSE_FORBIDDEN:
                        props.logOut();
                        break;
                    default:
                        // Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                        props.show_error("error", "Thông báo", "Hệ thống đang xảy ra lỗi " + status);
                        break;
                }
            }else{
                props.show_error("error", "Thông báo", "Hệ thống đang xảy ra lỗi !");
            }
        }).catch(err => {
            console.log(err);
            props.show_error("error", "Thông báo", "Hệ thống đang xảy ra lỗi !");
        });
    }

    return <>
        <header className="header-top" header-theme="dark" style={{backgroundColor:'#ffffff'}}>
            <div className="container-fluid">
                <div className="d-flex justify-content-between">
                    <div className="top-menu d-flex align-items-center">
                        <button type="button" className="btn-icon mobile-nav-toggle d-lg-none"  style={{display:'none'}}
                            // onClick={(e: any)=> this.mobileNavClick(e)}
                        >
                            <span></span>
                        </button>
                        <div className="header-search">
                            <div className="input-group">
                                {/* <img src="https://file.tengroup.com.vn/wwwroot/09122020/ZVpMoJLA79Kd4H0mWB7GxMtO2MPn2PBlSXbldaLiRhIryQv4I5ZTztPOHLSJ0S0zdU2QHvK2EeeUpPUr.png" style={{height:'60px'}} className="header-brand-img" alt="lavalite" />  */}
                            </div>
                        </div>
                    </div>
                    <div className="top-menu d-flex align-items-center">
                        <div className="dropdown">
                            <Popover content={menuHeader} position={Position.BOTTOM_RIGHT} className="custom-notification">
                                <img className="avatar" src={require("../../assets/images/user3.jpg")} alt=""/>
                            </Popover>
                        </div>
                    </div>

                    <Dialog
                        icon="unlock"
                        onClose={()=>{setShowDialog(false)}}
                        title="ĐỔI MẬT KHẨU"
                        isOpen={showDialog}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <div className="row">
                                <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4">
                                    <label>Mật khẩu cũ</label>
                                </div>
                                <div className="col-12 col-smaller-12 col-small-12 col-medium-8 col-large-8 col-xlarge-8 col-xxlarge-8">
                                    <Password value={oldpassword} onChange={(e: any) => setoldpassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="row" style={{marginTop: 10}}>
                                <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4">
                                    <label>Mật khẩu mới</label>
                                </div>
                                <div className="col-12 col-smaller-12 col-small-12 col-medium-8 col-large-8 col-xlarge-8 col-xxlarge-8">
                                    <Password value={password} onChange={(e: any) => setpassword(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button onClick={(e)=>{
                                    setoldpassword("");
                                    setpassword("");
                                    setShowDialog(false);
                                }}>Bỏ qua</Button>
                                <Button onClick={changePassword}>Đổi mật khẩu</Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </header>
    </>;
}

// class PageHeader extends React.Component<any, any>{

//     public componentDidMount(){
//         // console.log(this.props);
//     }


//     private searchOpen(e: any){
//         let headerSearch = document.querySelector(".header-search");
//         let inputSearch = (headerSearch as HTMLDivElement).querySelector(".form-control");
//         (headerSearch as HTMLDivElement).classList.add("open");
//         (inputSearch as HTMLInputElement).style.width = "200px";
//     }

//     private searchClose(e: any){
//         let headerSearch = document.querySelector(".header-search");
//         let inputSearch = (headerSearch as HTMLDivElement).querySelector(".form-control");

//         (inputSearch as HTMLInputElement).style.width = "0";
//         setTimeout(()=>{
//             (headerSearch as HTMLDivElement).classList.remove("open");
//         }, 300)
//     }

//     private mobileNavClick = (e: any) => {
//         let {mobileNavClick, isShowSidebar} = this.props;
//         return mobileNavClick(!isShowSidebar);
//     }

//     private RedirectPage(index: number){
//         console.log(index);
//         switch(index){
//             case 1:
//                 break;
//             default:
//                 break;
//         }
//     }

//     public render(){

        // const test = (
        //     <Menu>
        //         {/* <MenuItem icon="user" text="Tài khoản" /> */}
        //         <MenuItem icon="unlock" text="Đổi mật khẩu" onClick={(e)=> this.RedirectPage(1)}/>
        //         {/* <MenuItem icon="notifications" text="Thông báo"/>
        //         <MenuItem icon="inbox" text="Tin nhắn" /> */}
        //         <MenuDivider />
        //         <MenuItem icon="power" text="Đăng xuất" onClick={()=>this.props.logOut()}/>
        //     </Menu>
        // )

//         return (
//             <header className="header-top" header-theme="dark">
//                 <div className="container-fluid">
//                     <div className="d-flex justify-content-between">
//                         <div className="top-menu d-flex align-items-center">
//                             <button type="button" className="btn-icon mobile-nav-toggle d-lg-none" onClick={(e: any)=> this.mobileNavClick(e)}>
//                                 <span></span>
//                             </button>
//                             <div className="header-search">
//                                 <div className="input-group">
//                                     <span className="input-group-addon search-close" onClick={(e: any)=>this.searchClose(e)}><i className="ik ik-x"></i></span>
//                                     <input type="text" className="form-control" />
//                                     <span className="input-group-addon search-btn" onClick={(e: any)=>this.searchOpen(e)}><i className="ik ik-search"></i></span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="top-menu d-flex align-items-center">
//                             <div className="dropdown">
//                                 <Popover content={test} position={Position.BOTTOM_RIGHT} className="custom-notification">
//                                     <img className="avatar" src={require("../../assets/images/user3.jpg")} alt=""/>
//                                 </Popover>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <AppsModal />
//             </header>
//         )
//     }
// }
const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(actions.logOut()),
        show_error: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail)),
    }
}

export default connect(null, mapDispatchToProps)(PageHeader);