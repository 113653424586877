import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    isLoading: false,    
    listCost: [],
    listCostCustomer: [],
    listCostDriver: [],   
    listTransportation: [],
    listType: [],
    listShippingUnit: [],
    listHeight: [],
    listWareHouse: [],
    profile: null,
    message: null 
}

const reducer = (state = initialState , action:any) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.DATA_DEFAULT_INI:
            // console.log(action);
            return {
                isLoading: false,
                listCost: [],
                listCostCustomer: [],
                listCostDriver: [],   
                listTransportation: [],
                listType: [],
                listShippingUnit: [],
                listHeight: [],
                listWareHouse: [],
                profile: null,
                message: null 
            };
        case actionTypes.DATA_DEFAULT_SUCCESS:
            // console.log(actionTypes);
            return {
                ...state,
                listCost: action.listCost,
                listCostCustomer: action.listCostCustomer,
                listCostDriver: action.listCostDriver,
                listTransportation: action.listTransportation,
                listType: action.listType,
                listShippingUnit: action.listShippingUnit,
                listHeight: action.listHeight,
                listWareHouse: action.listWareHouse,
                profile: action.profile
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            };
        case actionTypes.DATA_DEFAULT_FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.message
            };
        default:
            return state;
    }
}

export default reducer;