import React, {useEffect, useMemo, useState}  from 'react';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders,axiosWithHeadersNoTokenUploadFile} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
import {Fieldset} from 'primereact/fieldset';
import Lightbox from '../../components/Lightbox-component/index';
// import {GetURL} from '../../common/config'
import Swal from 'sweetalert2';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { chunkArray } from '../../common/function';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
const _ = require('lodash');

const GroupImage = (props: any) => {
    const {
        data,
        // store,
        title,
        reloadPage,
        storeId,
        questionId,
        customerId,
        isMinium,
        dateSend
    } = props;

    useEffect(()=>{
        if(typeof(dateSend)==="string"){
            setDateQuestion1(new Date(parseInt(dateSend.substr(6,10)), parseInt(dateSend.substr(3,5)) - 1, parseInt(dateSend.substr(0,2))))
        }
    }, [dateSend])
    
    const [displayBasic, setDisplayBasic] = useState<boolean>(false);
    const [dateQuestion1, setDateQuestion1] = useState<any>(new Date());
    const [listFile, setListFile] = useState<any>([]);
    const [isCustomerWed, setIsCustomerWeb] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const onClick = (stateMethod) => {
        stateMethod(true);
    }

    useEffect(()=>{
        if(typeof(isMinium)==="boolean"){
            setIsCustomerWeb(isMinium);
        }
    }, [isMinium])
    

    const onHide = (stateMethod) => {
        stateMethod(false);
        setListFile([]);
    }

    const UploadData=()=>{
        if(listFile === null || listFile.length === 0 )
        {
            props.show_error('error', 'Lỗi', "Không có hình ảnh");
            return false;
        }
        if(dateQuestion1 === undefined || dateQuestion1 === null)
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn thời gian");
            return false;
        }
        axiosWithHeaders('POST', `${api.DATA_INSERT_IMAGE}`, {
            listData: listFile, 
            dateSend: moment(dateQuestion1).format('YYYY-MM-DD HH:mm:ss.SSS'),
            storeId: storeId
        })
        .then((response: any) => {
            const {status, message } = response.data;
            switch(status){
                case Status.STATUS_RESPONSE_OK:
                    reloadPage();
                    setDisplayBasic(false);
                    setListFile([]);
                    break;
                default:
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                    break;
            }
        }).catch(err => console.log(err));
       // props.reload();
    }

    const UploadCustomerData=()=>{
        if(listFile === null || listFile.length === 0 )
        {
            props.show_error('error', 'Lỗi', "Không có hình ảnh");
            return false;
        }
        if(dateQuestion1 === undefined || dateQuestion1 === null)
        {
            props.show_error('error', 'Lỗi', "Vui lòng chọn thời gian");
            return false;
        }
        axiosWithHeaders('POST', `${api.CUSTOMER_INSERT_IMAGE_WEB}`, {
            images: listFile, 
            dateCreate: moment(dateQuestion1).format('YYYY-MM-DD HH:mm:ss.SSS'),
            customerId: customerId
        })
        .then((response: any) => {
            const {status, message } = response.data;
            switch(status){
                case Status.STATUS_RESPONSE_OK:
                    reloadPage();
                    setDisplayBasic(false);
                    setListFile([]);
                    break;
                default:
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + status + JSON.stringify(message));
                    break;
            }
        }).catch(err => console.log(err));
       // props.reload();
    }

    const renderFooter = (stateMethod) => {
        return (
            <div>
                {(!isCustomerWed)&&(
                    <Button text="Thêm mới" icon="saved" intent="primary" onClick={() => UploadData()} disabled={loading}/>
                )}
                {(isCustomerWed)&&(
                    <Button text="Thêm mới" icon="saved" intent="primary" onClick={() => UploadCustomerData()} disabled={loading}/>
                )}
                <Button title="No" icon="cross" onClick={() => onHide(stateMethod)} className="p-button-secondary" >
                        <label>Không</label>
                </Button>
            </div>
        );
    }

    const DelImage = (item)=>{
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa hình ảnh này không ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_IMAGE_DETAIL_DATA, {detailDataId: item.detailDataId, dataId: item.dataId})
                .then((response: any) => {
                    if(response!==undefined && response!==null && Object.keys(response).length > 0){
                        let {status,message} = response.data;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                reloadPage();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                });
            }
        })
       
    }

    const DelImageCustomer = (item)=>{
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa hình ảnh này không ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                axiosWithHeaders('POST', api.DEL_DATA_IMAGE_CUSTOMER, {detailDataId: item.detailDataId, customerId: item.customerId})
                .then((response: any) => {
                    if(response!==undefined && response!==null && Object.keys(response).length > 0){
                        let {status,message} = response.data;
                        switch(status){
                            case Status.STATUS_RESPONSE_OK:
                                Swal.fire("Thông báo", "Xóa dữ liệu thành công", "success")
                                reloadPage();
                                break;
                            default:
                                Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi " + status, "error");
                                break;
                        }
                    }else{
                        Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Lỗi", "Hệ thống đang xảy ra lỗi...!, ", "error");
                });
            }
        })
       
    }

    const renderImage =()=>{
      
        if(data !== undefined && data !== null && data.length > 0)
        {
            let arr: any = [];
            _.forEach(data, function (value) {
                arr.push({
                    src: value.answer,
                    datesend: value.dateCreate +  " " + value.timeCreate,
                    detailDataId: value.detailDataId,
                    dataId: value.dataId,
                    customerId: value.customerId
                });
            });

            const styles = {
                width: "20%",
                height: `200px`,
                lineHeight: `200px`,
                display: "flex",
                //flexWrap: "wrap",
                flex: `0 0 20%`,
                padding: '10px'
            }


            
            return (
                <Lightbox 
                    images={arr} 
                    renderImageFunc={(idx, image, toggleLightbox) => {
                        return (
                            <div key={idx} style={styles}>
                                <div className="row" style={{ position: "relative", paddingTop: 20 }}>
                                    <div className='col-12'>
                                        <img
                                            alt={image.title}
                                            key={idx}
                                            src={image.src}
                                            // className='img-circle'
                                            style={{ width: "100%", height: 180, objectFit: "contain", border: "1px dashed #ccc", padding: 10 }}
                                            onClick={toggleLightbox.bind(null, idx)} 
                                        />
                                        <div style={{ bottom: 20, position: "absolute", width: '100%', textAlign: "center" }}>
                                            <p style={{ color: 'red'}}>{image.datesend}</p>
                                        </div>
                                    </div>
                                    {(props.profile.roleId ===1 || props.profile.roleId === 2) ?
                                    <div style={{ top: 30, position: "absolute", right: 30 }}>
                                        {(!isCustomerWed)&&(
                                            <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={()=>DelImage(image)} />
                                        )}
                                        {(isCustomerWed)&&(
                                            <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={()=>DelImageCustomer(image)} />
                                        )}
                                            
                                    </div> : null}
                                </div>
                            </div>
                        )
                    }}
                />
            )
        }
        return null;
    }
    const renderButton =()=>{
        if(props.profile.roleId ===1 || props.profile.roleId === 2){
            return (
                <div className='col-12' style={{position:'relative'}}>
                    <Button 
                        icon="plus" 
                        intent="success" 
                        large 
                        style={{marginLeft: 5, position:'absolute', top:(isMinium === true)? -50: -60, right: 10}} 
                        onClick={() => onClick(setDisplayBasic)} 
                    /> 
                </div>
            )
        }
        return null;
    }

    const convertMultipleImageto64 = (file) => {
        setLoading(true);

        var f = new FormData() as any;
        let list: any = [...listFile];
        file.map((image: any) => {
            f.append("files", image)
        });

        axiosWithHeadersNoTokenUploadFile('POST', `${api.FILE_UPLOAD_MULT}`, f)
            .then((response: any) => {
                if (response !== undefined && response.data !== undefined && response.data.result !== undefined) {
                    const {result} = response.data;
                    result.map((item: any) => {
                        var obj = {
                            // dataId: store.dataId,
                            questionId: questionId,
                            answer: item.result,
                            // dateCreate: dateQuestion1
                            infogroup: ""
                        }                    
                        list.push(obj); 
                    });
                    setListFile(list);                  
                }
            }).catch(err => console.log(err))
            .finally(()=>{
                setLoading(false);
            })
    }

    const onChangeFile = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileList = Array.from(files);
             convertMultipleImageto64(fileList)         
        }
    }

    const renderListImageUpload = useMemo(() => {
        let html: any = [];
        chunkArray(listFile, 4).forEach((item, index) => {
            html.push(
                <div key={index} className="row" style={{ paddingTop: 10 }}>
                    {item.map((items, idx) =>
                        <div className="col-md-3 text-center" key={idx}>
                            <img src={items.answer} alt="Hình preview" style={{ width: "100%", height: 150, objectFit: "contain", border: "1px dashed #ccc" }} />
                        </div>
                    )}
                </div>
            )
        });
        return html;
    }, [listFile])


    return  ((data !== undefined && data !== null && data.length > 0) ? <>
        <Fieldset legend={props.title} toggleable={true} className="fieldset-group-image booking-custom">
            {renderButton()}
            <div className='row' style={{paddingBottom: 30}}>
                <div className="col-12">
                {renderImage()}
                </div>
            </div>            
            <Dialog 
                header="Thêm mới hình ảnh" 
                visible={displayBasic} 
                style={{ width: '70%'}}
                contentStyle={{  height: '600px' }}
                onHide={() => onHide(setDisplayBasic)} 
                footer={renderFooter(setDisplayBasic)}
                maximizable={true}
            >
                <div className='row'>                    
                    <label className="col-4">Thời gian thực hiện</label>
                    <Calendar 
                        className="col-3" 
                        showTime={true} 
                        hourFormat="24" 
                        value={dateQuestion1} 
                        dateFormat="dd/mm/yy" 
                        touchUI
                        onChange={(e: any) => setDateQuestion1(e.value)}
                        >
                    </Calendar>
                </div>
                <div className="row" style={{paddingTop: 10, paddingBottom: 20}}>
                    <label className="col-4">{title}</label>
                    <input type="file"
                        name="import1"
                        className="col-4" multiple accept="image/*"
                        onChange={(event) => onChangeFile(event)}
                    />
                </div>
                {(loading) && (
                    <div className="row" style={{paddingTop: 10}}>
                        <div className="col-12 text-center">
                            <ProgressSpinner />
                        </div>                    
                    </div>
                )}
                {renderListImageUpload}
            </Dialog>
        </Fieldset>
        
    </> : null
    )
}

const mapStateToProps = (state:any) => {
    return {
        profile:state.auth.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        show_error: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupImage);