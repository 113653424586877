import React, {useEffect, useMemo} from 'react';
import { Fieldset } from 'primereact/fieldset';
import { 
    // listQuestion,
    getNameQuestion
} from '../../common/configData';

const GroupInfo = (props) => {
    const {
        title,
        data
    } = props;

    // useEffect(()=>{
    //     console.log(data);
    // }, [title, data])

    const renderBody = (object) =>{
        let html: any = []
        for(var field in object){
            if(field!=="dataId"){
                html.push(
                    <td key={field}>{object[field]}</td>
                )
            }
        }
        return html;
    } 

    const renderHearder = (object) =>{
        let html: any = []
        for(var field in object){
            if(field!=="dataId"){
                html.push(
                    <td key={field}>{getNameQuestion((field).replace("info", ""))}</td>
                )
            }
        }
        return html;
    } 

    const renderGroupInfo = useMemo(()=>{
        if(data!=undefined && data!==null && Object.keys(data).length > 0){
            return (
                <Fieldset legend={title} toggleable={true} className="fieldset-group-image booking-custom">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                {renderHearder(data)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {renderBody(data)}
                            </tr>
                        </tbody>
                    </table>
                </Fieldset>
            )
        }
        return null;
    }, [title, data])

    return (
        <React.Fragment>
            {renderGroupInfo}
        </React.Fragment>
    );
}

export default GroupInfo;