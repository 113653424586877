import * as types from '../contains/actionTypes';

export const show_error_init = () => {
    return {
        type: types.ERROR_INI
    };
};

export const show_error = (severity: any, summary: any, detail: any) => {
    return {
        type: types.show_error,
        severity,
        summary,
        detail
    }
}

