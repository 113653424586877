
export const configTable = {
    gridTitle: "Danh sách nhân viên - cửa hàng",
    storeProcedure: "StoreUser_List",
    isInsert: true,
    isUpdate: true,
    isDelete: true
}

export const filterTable = [
    {
        field: 'prefix',
        title: 'Từ khóa',
        type: 'string',
        defaultValue: '',
        placeholder: 'Tìm kiếm theo...'
    }
]

export const columnTable = [
    {
        field: 'stt',
        header: '#',
        width: 40,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeCode',
        header: 'Mã',
        width: 60,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'storeName',
        header: 'Tên cửa hàng',
        width: 150,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'name',
        header: 'Tài khoản',
        width: 150,
        align: 'left',
        fontSize: 11
    },
    {
        field: 'roleName',
        header: 'Quyền',
        width: 100,
        align: 'left',
        fontSize: 11
    }
    ,{
        field: 'action',
        header: '',
        width: 55,
        align: 'left',
        fontSize: 11,
        usedTemplate: true
    }
]