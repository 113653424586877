import * as types from '../contains/actionTypes';
import {
    DATA_DETAIL,
} from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';

import {
    axiosWithHeaders
} from '../contains/axiosWrapper';

import {
    show_error_init, 
    show_error
} from './error';

export const data_detail_init = () => {
    return {
        type: types.DATA_DETAIL_INI
    };
};

export const data_detail_success = (result: any, listCustomer: any, listCustomerSearch: any, listImageCustomer: any, tableReport: any) => {
    return {
        type: types.DATA_DETAIL_SUCCESS,
        result,
        listCustomer,
        listCustomerSearch,
        listImageCustomer,
        tableReport
    }
}

export const data_detail_loading = (loading: boolean) => {
    return {
        type: types.DATA_DETAIL_LOADING,
        loading
    }
}

export const data_detail_fail = (message) => {
    return {
        type: types.DATA_DETAIL_FAIL,
        message
    }
}

export function GetDataDetail(dataId: number) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(data_detail_init())
        dispatch(data_detail_loading(true));
        axiosWithHeaders('POST', DATA_DETAIL, {
            dataId: dataId,
            userId: localStorage.getItem(types.AUTH_USER_ID)
        })
            .then((data:any) => {
                // console.log(data)
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {
                        status, 
                        result, 
                        listCustomer, 
                        listCustomerSearch, 
                        listImageCustomer,
                        tableReport,
                        message
                    } = response;
                    // console.log(response);
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            dispatch(data_detail_success(result, listCustomer, listCustomerSearch, listImageCustomer, tableReport));
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Không thể lấy thông tin chi tiết !"))
                }
                dispatch(data_detail_loading(false));
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(data_detail_loading(false));
            });
    }
}

export function BackButtonData(history: any) {    
    return async dispatch => {
        dispatch(data_detail_init());
        history.push(router.ListData)
    }
}
