import * as actionTypes from '../contains/actionTypes';

const initialState: any = {
    data: [],
    loading: false,
    message: "",
    /*Phân trang*/
    first: 0,
    totalRecords: 0,
    pageNo: 0,
    pageRows: 100
}

const reducer = (state = initialState , action:any) => {
    switch (action.type) {
        case actionTypes.DATA_TABLE_INI:
            return {
                ...state
            };
        case actionTypes.DATA_TABLE_SUCCESS:
            return {
                ...state,
                data: action.data,
            };
        case actionTypes.DATA_TABLE_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case actionTypes.DATA_TABLE_UPDATE_PAGING:
            return {
                ...state,
                first: action.first,
                pageNo: action.pageNo,
                pageRows: action.pageRows,
                totalRecords: action.totalRecords
            };
        case actionTypes.DATA_TABLE_FAIL:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
}

export default reducer;