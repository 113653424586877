import React, {useEffect, useState} from 'react';
import FormLayout from '../../components/form/index';
import { Button } from "@blueprintjs/core";
import { connect } from 'react-redux';
import * as actions from '../../reducer/actions/index';
import { InputText } from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import * as router from "../../common/router";
import {
    Link
} from "react-router-dom";
import * as api from '../../reducer/contains/api'
import {axiosWithHeaders} from '../../reducer/contains/axiosWrapper'
import * as Status from '../../common/enum'
// var _ = require('lodash');

const Store = (props: any) => {
    const {
        store,
        loading,
        history,
        GetDetailStore,
        CreateStore,
        UpdateStore,
        BackStore,
        showError
    } = props;


    const [button, setButton] = useState<boolean>(true);
    const [storeId, setstoreId] = useState<number>(0);
    const [storeCode,    setStoreCode] = useState<string>('');
    const [storeName,    setStoreName] = useState<string>('');
    const [ward,   setWard] = useState<string>('');
    const [chain,   setChain] = useState<string>('');
    const [storeAddress, setStoreAddress] = useState<string>('');
    const [district, setDistrict] = useState<string>('');
    // const [kpiAM, setKPIAM] = useState<string>(0);
    // const [kpiPM, setKPIPM] = useState(0);
    // const [shiftPlan, setShiftPlan] = useState(0);
    const [area, setArea] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [listprovince, setListProvince] = useState([]);
    
    useEffect(() => {        
        GetProvince();
        let storeId = (props.match.params.id);
        if(storeId!==undefined && storeId!== null && isNaN(storeId) !==true ){
            setButton(false);
            GetDetailStore(storeId);
        }
    }, []);

    // console.log(store);

    useEffect(()=>{
        if(store !== undefined && store!== null && Object.keys(store).length > 0){
            const{	
                storeId,
                storeCode, 
                storeName,  
                storeAddress, 
                // ward,   
                // district,   
                area,   
                provinceId,
                chain
            } = store;

            setstoreId(storeId);
            setStoreCode(storeCode);
            setStoreAddress(storeAddress);
            setStoreName(storeName);
            // setWard(ward);
            // setDistrict(district);
            setArea(area);
            setChain(chain);
            setProvinceId(provinceId);
        }
    }, [store])

    const CheckData=()=>{
        showError(null, null, null);
        if(storeCode === undefined || storeCode === '')
        {
            showError('error', 'Lỗi', "Vui lòng nhập mã cửa hàng");
            return false;
        }
        return true;
    }

    const GetProvince=()=>{
        axiosWithHeaders('GET',`${api.LIST_PROVINCE}`,null)
        .then((data:any) => {
            if(data !== undefined && data !== null ){
                let response = data.data;
                let {status,message,result} = response;
                if(status === Status.STATUS_RESPONSE_OK)
                {
                   setListProvince(result)      
                }
                else{
                    props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + message);
                }
            }
            else{  props.show_error('error', 'Lỗi', "Có lỗi xảy ra !!!"); }
            
        }).catch(err => {
            props.show_error('error', 'Lỗi', "Có lỗi xảy ra !" + err);
        });
    }

    const SaveData=()=>{
        if(!CheckData()){
            return false;
        }
        let obj = {
            storeCode, 
            storeName,   
            storeAddress,   
            ward,   
            district,   
            area, 
            provinceId,  
            chain 
        }

        CreateStore(obj, history);
    }

    const SaveUpdateData=()=>{

        if(!CheckData()){
            return false;
        }

        var obj={
            storeId,
            storeCode, 
            storeName,   
            storeAddress,   
            ward,   
            district,   
            area, 
            provinceId,  
            chain   
        }
        
        UpdateStore(obj, history);
    }

    return (
        <div className="booking-layout">
            <FormLayout formTitle="CỬA HÀNG">
                <div className="row">
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4"></div>
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4">
                        <div className="row" style={{ paddingTop: 30 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Mã cửa hàng</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText 
                                    value={storeCode} 
                                    placeholder="Mã store"
                                    style={{ width: '100%' }} 
                                    onChange={(e: any) => setStoreCode(e.target.value)} 
                                    />
                            </div>
                        </div>                        
                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Tên cửa hàng</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText 
                                    value={storeName} 
                                    placeholder="Tên cửa hàng"
                                    style={{ width: '100%' }} 
                                    onChange={(e: any) => setStoreName(e.target.value)} 
                                />
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right" style={{paddingTop: 5}}>
                                <span>Địa chỉ cửa hàng</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputTextarea 
                                    value={storeAddress} 
                                    placeholder="Địa chỉ cửa hàng"
                                    style={{ width: '100%', height: 75 }} 
                                    onChange={(e: any) => setStoreAddress(e.target.value)} 
                                />
                            </div>
                        </div>

                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Tỉnh/Thành</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <Dropdown 
                                    value={provinceId} 
                                    optionLabel="provinceName" 
                                    optionValue="provinceCode" 
                                    options={listprovince} 
                                    placeholder="Chọn Tỉnh thành..." 
                                    filter={true} 
                                    filterPlaceholder="Tìm..." 
                                    filterBy="provinceCode,provinceName"
                                    filterMatchMode="contains"
                                    style={{width : '100%'}} 
                                    onChange={(e: any)=> setProvinceId(e.value)}
                                />
                            </div>
                        </div>

                        {/* <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Quận/Huyện</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText 
                                    value={district} 
                                    placeholder="Tên quận/huyện"
                                    style={{ width: '100%' }} 
                                    onChange={(e: any) => setDistrict(e.target.value)} 
                                />
                            </div>
                        </div>

                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Phường/Xã</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText 
                                    value={ward} 
                                    placeholder="Tên phường/xã"
                                    style={{ width: '100%' }} 
                                    onChange={(e: any) => setWard(e.target.value)} 
                                />
                            </div>
                        </div> */}
                        
                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Vùng</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText 
                                    value={area} 
                                    placeholder="Tên vùng"
                                    style={{ width: '100%' }} 
                                    onChange={(e: any) => setArea(e.target.value)} 
                                />
                            </div>
                        </div>

                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4 text-right align-self-center">
                                <span>Hệ thống</span>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <InputText 
                                    value={chain} 
                                    placeholder="Nhập hệ thống"
                                    style={{ width: '100%' }} 
                                    onChange={(e: any) => setChain(e.target.value)} 
                                />
                            </div>
                        </div>                        

                        <div className="row" style={{ paddingTop: 20 }}>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                <Button
                                    intent="none"
                                    className="btn-block"
                                    large
                                    onClick={(e)=> BackStore(history)}
                                    disabled={loading}
                                >
                                    QUAY LẠI
                                </Button>
                            </div>
                            <div className="col-12 col-smaller-12 col-small-12 col-medium-6 col-large-6 col-xlarge-6 col-xxlarge-6">
                                {button ?     <Button
                                    intent="success"
                                    className="btn-block"
                                    large
                                    disabled={loading}
                                    onClick={()=>SaveData()}
                                >
                                    THÊM MỚI
                                </Button>:
                                <Button
                                    intent="primary"
                                    className="btn-block"
                                    large
                                    disabled={loading}
                                    onClick={()=>SaveUpdateData()}
                                >
                                    CẬP NHẬT
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-smaller-12 col-small-12 col-medium-4 col-large-4 col-xlarge-4 col-xxlarge-4"></div>
                </div>
            </FormLayout>
        </div>
    )

}

const mapStateToProps = state => {
    // console.log(state);
    return {
        profile: state.auth.profile,
        store: state.store.detail,
        loading: state.store.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        GetDetailStore: (storeId: number) => dispatch(actions.GetDetailStore(storeId)),
        CreateStore: (objUser: any, history: any) => dispatch(actions.CreateStore(objUser, history)),
        UpdateStore: (objUser: any, history: any) => dispatch(actions.UpdateStore(objUser, history)),
        BackStore: (history: any) => dispatch(actions.BackButtonStore(history)),
        showError: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Store);
