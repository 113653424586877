
//import * as Status from './enum';
import moment from "moment"; 
const _ = require("lodash");
// // import Swal from 'sweetalert2';

// export function formatCurrency(value: number){
//     if(typeof(value)==="string")
//         value = parseInt(value);
//     if(value===undefined || value === null || isNaN(value)===true){
//         value = 0;
//     }
//     return Number((value).toFixed(1)).toLocaleString()
// }

export const chunkArray = (arr: any, size: number) => {
    var groupedArray: any = [];
    for(var i = 0; i < arr.length; i += size) {
        groupedArray.push(
            arr.slice(i, i+size)
        );
    }
    // console.log(groupedArray);
    return groupedArray ;
}

export const groupBy = (dataToGroupOn: any, fieldNameToGroupOn: string, fieldNameForGroupName: string, fieldNameForChildren: string) => {
    var result = _.chain(dataToGroupOn)
     .groupBy(fieldNameToGroupOn)
     .toPairs()
     .map(function (currentItem) {
         return _.zipObject([fieldNameForGroupName, fieldNameForChildren], currentItem);
     })
     .value();
    return result;
}