import * as types from '../contains/actionTypes';
import {
    CUSTOMER_DETAIL,
} from '../contains/api';
import * as Status from '../../common/enum';
import * as router from '../../common/router';

import {
    axiosWithHeaders
} from '../contains/axiosWrapper';

import {
    show_error_init, 
    show_error
} from './error';

export const customer_detail_init = () => {
    return {
        type: types.CUSTOMER_DETAIL_INI
    };
};

export const customer_detail_success = (result: any) => {
    return {
        type: types.CUSTOMER_DETAIL_SUCCESS,
        result
    }
}

export const customer_detail_loading = (loading: boolean) => {
    return {
        type: types.CUSTOMER_DETAIL_LOADING,
        loading
    }
}

export const customer_detail_fail = (message) => {
    return {
        type: types.CUSTOMER_DETAIL_FAIL,
        message
    }
}

export function CustomerDetail(customerId: number) {    
    return async dispatch => {
        dispatch(show_error_init());
        dispatch(customer_detail_init())
        dispatch(customer_detail_fail(true));
        axiosWithHeaders('GET', `${CUSTOMER_DETAIL}?customerId=${customerId}`, null)
            .then((data:any) => {
                // console.log(data)
                if(data!==undefined && data!==null && Object.keys(data).length > 0){
                    let response = data.data;
                    let {
                        status, 
                        message,
                        result
                    } = response;
                    console.log(response);
                    switch(status){
                        case Status.STATUS_RESPONSE_OK:
                            dispatch(customer_detail_success(result));
                            break;
                        default:
                            dispatch(show_error("error", "Lỗi", message))
                            break;
                    }
                }else{
                    dispatch(show_error("error", "Lỗi", "Không thể lấy danh sách sup-pg !"))
                }
                dispatch(customer_detail_loading(false));
            }).catch(err => {
                console.log(err);
                dispatch(show_error("error", "Lỗi", err.toString()))
                dispatch(customer_detail_loading(false));
            });
    }
}

export function ResetCustomerDetail() {    
    return async dispatch => {
        dispatch(customer_detail_success(null));
    }
}
