import React, {useState, useEffect} from 'react';
import {filterTable, columnTable, configTable} from '../../common/configGrid/configStore';
import { Button } from "@blueprintjs/core";
import {Fieldset} from 'primereact/fieldset';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
} from "react-router-dom";
import * as router from '../../common/router';
import RenderFilter from '../../components/filter/update';
import FormLayout from '../../components/form/index';
import DataTables from '../../components/datatable/index';
import * as actions from '../../reducer/actions/index';
import * as config from '../../common/config';

import Swal from 'sweetalert2';

const _ = require("lodash");

const ListStore = (props: any) =>{
    const {
        profile,
        data,
        first,
        loading,
        pageNo,
        pageRows,
        totalRecords,
        LoadDataTable,
        DeleteStore
    } = props;
    const [listFilter, setListFilter] = useState<any>(filterTable);
    const [isNewFilter, setIsNewFilter] = useState<boolean>(false);

    const onChangeFilter = (value, index) =>{
        let filters = _.cloneDeep(listFilter);
        filters[index].value = value;
        setListFilter(filters);
        setIsNewFilter(true);
    }

    const renderFilter = () => {
        // console.log(filter);
        return(
            <Fieldset legend="TÌM KIẾM NÂNG CAO" className="booking-custom" style={{position: "relative"}}>
                <RenderFilter 
                    listFilter={listFilter} 
                    isNewFilter={isNewFilter}
                    onClickFilter={(e: any) => LoadDataTable(configTable, listFilter, config.DEFAULT_PAGE_NO, config.DEFAULT_PAGE_SIZE, true)}
                    onChangeFilter={onChangeFilter}
                />
            </Fieldset>
        )
    }

    useEffect(() => {
        LoadDataTable(configTable, listFilter, config.DEFAULT_PAGE_NO, config.DEFAULT_PAGE_SIZE, true);
    }, []);


    const delStore = (e: any, storeId: number) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa cửa hàng này khỏi danh sách ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: "Hủy"
        }).then((result) => {
            if (result.value) {
                const objDel = {
                    storeId: storeId
                }
                DeleteStore(objDel, pageNo, pageRows);
            }
        })
    }

    const cellTemplate = (row: any, cell: any) =>{
        let {field} = cell;
        switch(field){            
            case 'action':
                return (
                    <>
                        <Link className="bp3-button bp3-small bp3-intent-primary add-booking" to={router.Store+"/"+ row["storeId"]}>
                            <i className="fa fa-edit"></i>
                        </Link>
                        <Button icon="trash" intent="danger" small style={{marginLeft: 5}} onClick={(e)=> delStore(e, row["storeId"])}/>
                    </>
                )
            default:
                return null;
        }
    }

    const renderDataTable = () => {
        if(columnTable!==undefined && columnTable!== null && Array.from(columnTable).length > 0){
            return (
                <DataTables 
                    columns={columnTable}
                    loading={loading}
                    data={data}
                    rows={pageRows}
                    totalRecords={totalRecords}
                    first={first}
                    onPage={onPage}
                    cellTemplate={cellTemplate}
                />
            )
        }
        return null
    }

    

    const onClickAdd = (e: any) => {
        e.preventDefault();
        props.history.push(router.Store);
    }

    const onPage = (e: any) => {
        let {page, rows} = e;
        LoadDataTable(configTable, listFilter, page, rows, false)
    }

    return (
        <>
            <FormLayout formTitle="Cửa hàng" showAdd={true} onClickAdd={(e: any)=> onClickAdd(e)}>
                {
                    renderFilter()
                }
                {
                    renderDataTable()
                }
            </FormLayout>
        </>
    )
}

const mapStateToProps = state => {
    return {
        profile: state.auth.profile,
        data: state.datatable.data,
        pageRows: state.datatable.pageRows,
        pageNo: state.datatable.pageNo,
        loading: state.datatable.loading,
        totalRecords: state.datatable.totalRecords,
        first: state.datatable.first,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        showError: (severity,summary,detail) => dispatch(actions.show_error(severity,summary,detail)),
        LoadDataTable: (settingTable: any, filterTable: any, pageNo: number, pageSize: number, isNewFilter: boolean) => dispatch(actions.LoadDatatable(settingTable, filterTable, pageNo, pageSize, isNewFilter)),
        DeleteStore: (objDel: any, pageNo: number, pageRows: number) =>dispatch(actions.DeleteStore(objDel, pageNo, pageRows)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListStore)) as any;
